import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react';
import { Role } from '../../service/role';
import api from '../../service/api';
import { getRole, isAuthenticated } from '../../service/auth';

import ReactGA from 'react-ga';

export class Usuario extends Component {
    constructor() {
        super();
        this.state = {
            id: null,
            email: null,
            tipo: null,
            statusBtnApagar: false
        }
    }
    componentDidMount() {
        const role = getRole();
        if (!isAuthenticated() || role[1] !== Role.Admin) {
            window.location.href = `/#/login`;
        }
        this.getPerfilByEmail(this.props.match.params.email);
    }

    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/usuario');
    }

    voltar() {
        window.history.go(-1);
    }

    getPerfilByEmail = async (email) => {
        try {
            const response = await api.get(`/usuarios/find/${email}`);
            if (response.status === 200) {
                this.setState({ id: response.data.id });
                this.setState({ nome: response.data.nome });
                this.setState({ email: response.data.email });
                this.setState({ tipo: response.data.tipo });
            }
        } catch (err) {
            console.error(err);
        }
    }

    handleChange = (event) => {
        const { target: { name, value } } = event
        this.setState({ [name]: value })
    }

    apagar = () => {
        if (window.confirm("Deseja realmente deletar esse usuário?")) {
            api.delete(`/usuarios/${this.state.id}`)
                .then(res => {
                    if (res.status === 204) {
                        this.growl.show({ severity: 'success', summary: 'Successo', detail: 'Usuário deletado com sucesso' });
                        this.setState({ statusBtnApagar: false });
                        window.location.href = "/#/usuarios";
                    }
                })
                .catch(error => {
                    this.setState({ statusBtnApagar: true });
                    this.growl.show({ severity: 'error', summary: 'Erro', detail: 'Não foi possível deletar o Usúario' });
                });
        }

    }

    validaForm = () => {
        let isValid = true;
        let erros = [];
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
        let tamanhoNome = this.state.nome ? this.state.nome.length : 0;
        if (tamanhoNome < 5) {
            erros.push({ severity: 'error', summary: 'Nome inválido', detail: 'Nome completo é obrigatório' });
            isValid = false;
        }
        if (!strongRegex.test(this.state.senha)) {
            erros.push({ severity: 'error', summary: 'Senha inválida', detail: 'Senha deve conter no mínimo 1 caracter especial, 1 letra minuscula, 1 letra maiuscula e ter 8 caracteres' });
            isValid = false;
        }
        if (this.state.confirmaSenha !== this.state.senha) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'As senhas não conferem' });
            isValid = false;
        }
        if (erros.length) {
            this.growl.show(erros);
        }
        return isValid;
    }
    render() {
        const statusBotao = this.state.statusBtnApagar;
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <h1>Dados do Usuário</h1>
                        <div className="p-grid p-fluid">

                            <div className="p-col-12 p-lg-12">
                                <Growl ref={(el) => this.growl = el} />
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon"><i className="pi pi-user" /></span>
                                            <InputText
                                                placeholder="Nome Completo"
                                                name="nome"
                                                maxLength="30"
                                                value={this.state.nome}
                                                onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon"><i className="pi pi-envelope" /></span>
                                            <InputText
                                                placeholder="Email"
                                                disabled
                                                name="email"
                                                maxLength="30"
                                                value={this.state.email}
                                                onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    
                                    <div className="p-col-12 p-md-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon"><i className="pi pi-users" /></span>
                                            <InputText
                                                type="text"
                                                placeholder="Tipo"
                                                name="tipo"
                                                value={this.state.tipo}
                                                onChange={this.handleChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-6 p-md-3" style={{ align: 'right' }}>
                                <Button label="Apagar" icon="pi pi-trash" iconPos="left" disabled={statusBotao} className="p-button-raised p-button-rounded p-button-danger" style={{ marginBottom: '10px' }} onClick={this.apagar} />
                            </div>
                            <div className="p-col-6 p-md-3" style={{ align: 'right' }}>
                                <Button label="Voltar" icon="pi pi-arrow-left" iconPos="left" className="p-button-secondary" style={{ marginBottom: '10px' }} onClick={this.voltar} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}