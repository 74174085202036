import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Panel } from 'primereact/panel';
import { Growl } from 'primereact/growl';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import React, { Component } from 'react';
import api from '../../service/api';
import { isAuthenticated, getRole } from '../../service/auth';
import { Role } from '../../service/role';
import Cookies from 'js-cookie'
import ReactGA from 'react-ga';
import { Event } from '../../service/analytics';

export class BuscaProfissionais extends Component {
    constructor() {
        super();
        this.state = {
            profissionais: [],
            tecnologias: [],
            tecnologiasSelecionadas: [],
            desenvolvedor: {},
            tecnologiasDev: [],
            visible: false,
            estado: null,
            cidade: null,
            statusBtnBuscar: false,
            estados: [
                { label: 'Informe o Estado', value: null },
                { label: 'AC', value: 'AC' },
                { label: 'AL', value: 'AL' },
                { label: 'AP', value: 'AP' },
                { label: 'AM', value: 'AM' },
                { label: 'BA', value: 'BA' },
                { label: 'CE', value: 'CE' },
                { label: 'DF', value: 'DF' },
                { label: 'ES', value: 'ES' },
                { label: 'GO', value: 'GO' },
                { label: 'MA', value: 'MA' },
                { label: 'MT', value: 'MT' },
                { label: 'MS', value: 'MS' },
                { label: 'MG', value: 'MG' },
                { label: 'PA', value: 'PA' },
                { label: 'PB', value: 'PB' },
                { label: 'PR', value: 'PR' },
                { label: 'PE', value: 'PE' },
                { label: 'PI', value: 'PI' },
                { label: 'RJ', value: 'RJ' },
                { label: 'RN', value: 'RN' },
                { label: 'RS', value: 'RS' },
                { label: 'RO', value: 'RO' },
                { label: 'RR', value: 'RR' },
                { label: 'SC', value: 'SC' },
                { label: 'SE', value: 'SE' },
                { label: 'SP', value: 'SP' },
                { label: 'TO', value: 'TO' }
            ],
        };
    }

    componentDidMount() {
        var role = getRole();
        if (!isAuthenticated() || (role[0] === Role.User && role[1] !== Role.Admin)) {
            window.location.href = `/#/login`;
        }
        this.tecs();
        this.initializeReactGA();
    }
    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/findProfissionais');
    }
    tecs = async () => {
        const response = await api.get('/tecnologias/combo');
        this.setState({ tecnologias: response.data });
    }
    voltar = (e) =>{
        e.preventDefault();
        window.history.go(-1);
    }
    isDesktop() {
        return window.innerWidth > 1024;
    }
    search = async (e) => {
        e.preventDefault();
        Event("Empresas", "Buscando Profissionais", "Botao buscar acionado");
        this.setState({ loading: true });
        this.setState({ statusBtnBuscar: true });
        try {
            let desenvolvedor = {
                cidade: this.state.cidade,
                estado: this.state.estado === '' ? null : this.state.estado,
                tecnologias: this.state.tecnologiasSelecionadas === "null" ? '' : this.state.tecnologiasSelecionadas
            };
            const response = await api.post('/desenvolvedores/search', desenvolvedor);
            if (!response.data.length) {
                this.growl.show({ severity: 'error', summary: 'Resultado', detail: 'Nenhum profissional encontrado' });
            }
            this.setState({ statusBtnBuscar: false });
            this.setState({ profissionais: response.data });
        } catch (err) {
            this.setState({ statusBtnBuscar: false });
            this.growl.show({ severity: 'error', summary: 'Resultado', detail: 'Nenhum profissional encontrado' });
        } finally {
            this.setState({ loading: false });
        }

    }
    getDevById = async (id) => {
        const response = await api.get(`/desenvolvedores/${id}`);
        const dev = response.data;
        this.setState({ desenvolvedor: dev });
        if (dev.tecnologias) {
            this.setState({ tecnologiasDev: dev.tecnologias });
        }
    }

    showDesenvolvedor = (e) => {
        Event("Empresas", "Exibindo profissional", "Link exibir acionado");
        const idDev = e.data.id;
        this.getDevById(idDev);
        this.setState({ visible: true });
    }

    handleChange = (event) => {
        const { target: { name, value } } = event
        this.setState({ [name]: value })
    }

    limpar = (e) => {
        this.setState({ profissionais: null });
        this.setState({ estado: null });
        this.setState({ tecnologiasSelecionadas: null });
        this.setState({ cidade: '' });
        Cookies.remove("cidade");
        Cookies.remove("estado");
        Cookies.remove("tecnologias");
    }
    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-search" className="p-button-raised p-button-rounded p-button-success"></Button>
        </div>;
    }

    render() {
        var profissionaisCount = this.state.profissionais ? this.state.profissionais.length : 0;
        var footer = "Total de  " + profissionaisCount + ' profissionais';
        const { loading } = this.state;
        const statusBotao = this.state.statusBtnBuscar;
        const dialogFooter = (
            <div>
                <Button icon="pi pi-times" className="p-button-raised p-button-rounded p-button-danger" onClick={() => this.setState({ visible: false })} label="Fechar" />
            </div>
        );
        return (
            <div className="p-grid">
                {loading && <img src="assets/layout/images/preloader.gif" width="35" alt="Carregando" />}
                <div className="p-col-12">
                    <div className="card">
                        <h1>Busca de Profissionais</h1>
                        <div className="p-grid p-fluid">
                            <Growl ref={(el) => this.growl = el} />
                            <div className="p-col-12 p-lg-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-12">
                                        <MultiSelect
                                            placeholder="Tecnologias"
                                            value={this.state.tecnologiasSelecionadas}
                                            options={this.state.tecnologias}
                                            onChange={event => this.setState({ tecnologiasSelecionadas: event.value })}
                                            filter={true} />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown
                                            name="estado"
                                            filter={true}
                                            options={this.state.estados}
                                            value={this.state.estado}
                                            onChange={event => this.setState({ estado: event.value })}
                                            autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <InputText
                                            placeholder="Cidade"
                                            name="cidade"
                                            value={this.state.cidade}
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="p-col-6 p-md-3" style={{ align: 'right' }}>
                                        <Button label="Buscar" icon="pi pi-search" iconPos="left" disabled={statusBotao} className="p-button-raised p-button-rounded p-button-primary" style={{ marginBottom: '10px' }} onClick={this.search} />
                                    </div>
                                    <div className="p-col-6 p-md-3" style={{ align: 'right' }}>
                                        <Button label="Limpar" icon="pi pi-undo" iconPos="left" className="p-button-raised p-button-rounded p-button-secondary" style={{ marginBottom: '10px' }} onClick={this.limpar} />
                                    </div>
                                    <div className="p-col-6 p-md-3" style={{ align: 'right' }}>
                                        <Button label="Voltar" icon="pi pi-arrow-left" iconPos="left" className="p-button-raised p-button-rounded p-button-danger" style={{ marginBottom: '10px' }} onClick={this.voltar} />
                                    </div>

                                    {profissionaisCount > 0 &&
                                        <div className="p-col-12 p-lg-12">
                                            <DataTable value={this.state.profissionais} emptyMessage="Nenhum registro encontrado" style={{ marginBottom: '20px' }} responsive={true}
                                                selectionMode="single" onRowClick={this.showDesenvolvedor} paginator={true} rows={10} footer={footer} >
                                                <Column field="nome" header="Nome" sortable={true} />
                                                <Column field="estado" header="Estado" sortable={true} style={{ width: '12%' }} />
                                                <Column field="cidade" header="Cidade" sortable={true} style={{ width: '20%' }} />
                                                <Column body={this.actionTemplate} style={{ textAlign: 'center', width: '6em' }} />
                                            </DataTable>
                                        </div>
                                    }
                                </div>
                                <Dialog header="Exibir Profissional" visible={this.state.visible} style={{ width: this.isDesktop() ? '60vw' : '100vw' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                                    <div className="p-col-12 p-lg-12">
                                        <div className="p-col-12 p-md-12">
                                            <Panel header="Dados Pessoais">
                                                <div className="p-col-12 p-md-12">
                                                    <label htmlFor="cb2"><b>Nome:</b> {this.state.desenvolvedor.nome}</label>
                                                </div>
                                                <div className="p-col-12 p-md-12">
                                                    <label htmlFor="cb2"><b>Email:</b><a href={`mailto:${this.state.desenvolvedor.email}`}> {this.state.desenvolvedor.email} </a> </label>
                                                </div>
                                                <div className="p-col-12 p-md-4">
                                                    <label htmlFor="cb2"><b>Sexo:</b> {this.state.desenvolvedor.sexo === 'M' ? 'Masculino' : 'Feminino'}</label>
                                                </div>
                                            </Panel>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Panel header="Endereço">
                                                <div className="p-col-12 p-md-2">
                                                    <label htmlFor="cb2"><b>Estado: </b> {this.state.desenvolvedor.estado}</label>
                                                </div>
                                                <div className="p-col-12 p-md-12">
                                                    <label htmlFor="cb2"><b>Cidade: </b> {this.state.desenvolvedor.cidade}</label>
                                                </div>
                                            </Panel>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Panel header="Redes Sociais">
                                                <div className="p-col-12 p-md-12">
                                                    <label htmlFor="cb2"><b>Github: </b> {this.state.desenvolvedor.gitHub}</label>
                                                </div>
                                                <div className="p-col-12 p-md-12">
                                                    <label htmlFor="cb2"><b>Linkedin: </b> {this.state.desenvolvedor.linkedin}</label>
                                                </div>
                                            </Panel>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Panel header="Tecnologias do Profissional">
                                                {
                                                    this.state.tecnologiasDev && this.state.tecnologiasDev.map((tecnologia, index) => {
                                                        return (
                                                            <div className="p-col-12 p-lg-6">
                                                                <div className="p-grid" key={index}>
                                                                    <div className="p-col-12 p-md-6">
                                                                        <label htmlFor="cb2" className="p-checkbox-label">{tecnologia}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </Panel>
                                        </div>
                                    </div>
                                </Dialog>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}