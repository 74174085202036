import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import React, { Component } from 'react';
import api from '../../service/api';
import { isAuthenticated, getRole} from '../../service/auth';
import { Role } from '../../service/role';
import ReactGA from 'react-ga';
import { Event } from '../../service/analytics';

export class Usuarios extends Component {

    constructor() {
        super();
        this.state = {
            usuarios: [],
            loading: false
        };
    }

    componentDidMount() {
        var role = getRole();
        if (!isAuthenticated() || (role[0] !== Role.Company && role[1] !== Role.Admin)) {
            window.location.href = `/#/login`;
        }
        this.getUsuarios();
        this.initializeReactGA();
    }

    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/usuarios');
    }

    getUsuarios = () => {
        this.setState({ loading: true });
        api.get("/usuarios")
            .then(res => {
                if (res.status === 200) {
                    this.setState({ usuarios: res.data });
                    this.setState({ loading: false });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({ loading: false });
            });
    }
    
    showUsuario = (e) => {
        Event("Admin","Ver usuario","Ver usuario");
        const email = e.data.email;
        window.location.href = `/#/usuario/${email}`;
    }
    
    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-search" className="p-button-raised p-button-rounded p-button-success"></Button>
        </div>;
    }
    
    render() {
        var usuariosCount = this.state.usuarios ? this.state.usuarios.length : 0;
        var footer = "Total de  " + usuariosCount + ' usuários';
        const { loading } = this.state;
        return (
            <div className="p-grid">
                {loading && <img src="assets/layout/images/preloader.gif" width="35" alt="Carregando" />}
                <div className="p-col-12">
                    <div className="card">
                        <h1>Usuários</h1>
                        <div className="p-grid p-fluid">
                            <Growl ref={(el) => this.growl = el} />
                            <div className="p-col-12 p-lg-12">
                                <DataTable value={this.state.usuarios} emptyMessage="Nenhum registro encontrado" style={{ marginBottom: '20px' }} responsive={true}
                                    selectionMode="single" onRowClick={this.showUsuario} paginator={true} rows={10} footer={footer} >
                                    <Column field="nome" header="Nome" sortable={true} />
                                    <Column field="email" header="Email" sortable={true} />
                                    <Column field="tipo" header="Tipo" sortable={true} style={{ width: '12%' }} />
                                    <Column field="dataCriacao" header="Criação" sortable={true} style={{ width: '15%' }} />
                                    <Column body={this.actionTemplate} style={{textAlign:'center', width: '6em'}}/>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}