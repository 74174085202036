import Cookies from 'js-cookie'

export const TOKEN_KEY = "token";

export const jwt = require('jsonwebtoken');

export const EMAILEMPRESA = "emailEmpresa";

export const IDVAGA = "idVaga";

export const IDDEV = "idDev";

export const EMAIL = "email";

export const isAuthenticated = () => Cookies.get(TOKEN_KEY) !== undefined;

export const getToken = () => Cookies.get(TOKEN_KEY);

export const getUser = () => getToken() ? JSON.stringify(jwt.decode(getToken())) : null;

export const getRole = () => getUser() ? JSON.parse(getUser()).role : [];

export const login = token => {
  Cookies.set(TOKEN_KEY,token, { expires: 7 });
};

export const logout = () => {
  Cookies.remove(TOKEN_KEY);
  Cookies.remove(EMAILEMPRESA);
  Cookies.remove(IDVAGA);
  Cookies.remove(IDDEV);
  Cookies.remove(EMAIL);
  window.location.href = "/#/login";
  window.location.reload();
};

