import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import api from '../../service/api';
import { isAuthenticated, login, getRole } from '../../service/auth';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Event } from '../../service/analytics';
import { Role } from '../../service/role';

export class Registro extends Component {

    constructor() {
        super();
        this.state = {
            email: null,
            senha: null,
            confirmaSenha: null,
            loading: false,
            tipo: null,
            statusBtnSalvar: false,
            tipos: [
                { label: 'Informe o tipo', value: null },
                { label: 'Profissional de TI', value: 'D' },
                { label: 'Recrutador', value: 'R' }
            ],
        };
    }
    isDesktop() {
        return window.innerWidth > 1024;
    }
    componentDidMount() {
        if (isAuthenticated()) {
            window.location.href = `/#/dashboard`;
        }
        this.initializeReactGA();
    }

    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/registrar');
    }

    validaForm = () => {
        let isValid = true;
        let erros = [];
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
        const emailRegex = new RegExp("^[a-z0-9][-_.+!#$%&'*/=?^`{|]{0,1}([a-z0-9][-_.+!#$%&*/=?^`{|]{0,1})*[a-z0-9]@[a-z0-9][-.]{0,1}([a-z][-.]{0,1})*[a-z0-9].[a-z0-9]{1,}([.-]{0,1}[a-z]){0,}[a-z0-9]{0,}$");
        let tamanhoNome = this.state.nome ? this.state.nome.length : 0;
        if (tamanhoNome < 5) {
            erros.push({ severity: 'error', summary: 'Nome inválido', detail: 'Nome completo é obrigatório' });
            isValid = false;
        }
        if (!emailRegex.test(this.state.email)) {
            erros.push({ severity: 'error', summary: 'Email inválido', detail: 'Email inválido' });
            isValid = false;
        }
        if (!strongRegex.test(this.state.senha)) {
            erros.push({ severity: 'error', summary: 'Senha inválida', detail: 'Senha deve conter no mínimo 1 caracter especial, 1 letra minuscula, 1 letra maiuscula e ter 8 caracteres' });
            isValid = false;
        }
        if (this.state.confirmaSenha !== this.state.senha) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'As senhas não conferem' });
            isValid = false;
        }
        if (!this.state.tipo) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'Tipo é obrigatório' });
            isValid = false;
        }
        if (erros.length) {
            this.growl.show(erros);
        }
        return isValid;
    }

    registrar = async (e) => {
        e.preventDefault();
        Event("Geral", "Criar Usuario", "Botao registrar acionado");
        const { nome, email, senha, tipo } = this.state;
        if (this.validaForm()) {
            this.setState({ loading: true });
            this.setState({ statusBtnSalvar: true });
            try {
                const response = await api.post("/usuarios", { nome, email, senha, tipo });
                if (response.status === 201) {
                    let user = {
                        username: this.state.email,
                        password: this.state.senha
                    }
                    this.growl.show({ severity: 'success', summary: 'Successo', detail: 'Usuário criado com sucesso' });
                    setTimeout(() => {
                        this.setState({ loading: false });
                        this.login(user);
                    }, 2000);

                }
            } catch (err) {
                this.setState({ statusBtnSalvar: false });
                this.setState({ loading: false });
                if (err.response) {
                    this.growl.show({ severity: 'error', summary: 'Resultado', detail: err.response.data.message });
                }
            }
        }
    }

    login = async (user) => {
        try {
            const response = await api.post("/login", user);
            if (response.status === 200 && response.data.token) {
                login(response.data.token);
                const role = getRole();
                let url;
                if (role && role[0] === Role.User) {
                    url = "/desenvolvedor";
                } else {
                    url = "/empresa";
                }
                this.props.history.push(url);
                window.location.reload();
            }
        } catch (e) {
            console.error(e);
        }
    }


    handleChange = (event) => {
        const { target: { name, value } } = event
        this.setState({ [name]: value })
    }

    cancelar() {
        window.location.href = "/#/login";
    }

    render() {
        const { loading } = this.state;
        const statusBotao = this.state.statusBtnSalvar;
        let dev = "assets/layout/images/dev_7.jpg";
        let css = this.isDesktop() ? "p-col-6 p-md-6 p-lg-6 p-fluid content" : "p-col-10 p-md12 p-lg-10 p-fluid content";
        let isFirefox = typeof InstallTrigger !== 'undefined';
        let tamanhoColuna = 'p-col-12';
        if (isFirefox) {
            tamanhoColuna = 'p-col-11';
        }

        return (

            <div className="bg-dim full-bg-size" style={{
                backgroundImage: `url(${dev})`, display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderradius: '10px'
            }}>
                <div className={css}>
                    {loading && <img src="assets/layout/images/preloader.gif" width="35" alt="Carregando" />}
                    <Growl ref={(el) => this.growl = el} />
                    <div className="registrar-form">
                        <h5>Criar sua conta</h5>

                            <div className={tamanhoColuna}>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="pi pi-user" /></span>
                                    <InputText type="text"
                                        placeholder="Nome"
                                        name="nome"
                                        size="100"
                                        value={this.state.nome}
                                        onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className={tamanhoColuna}>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="pi pi-envelope" /></span>
                                    <InputText type="text"
                                        placeholder="Email"
                                        name="email"
                                        size="100"
                                        value={this.state.email}
                                        onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className={tamanhoColuna}>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="pi pi-key" /></span>
                                    <InputText type="password"
                                        placeholder="Senha"
                                        size="100"
                                        name="senha"
                                        value={this.state.senha}
                                        onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className={tamanhoColuna}>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="pi pi-key" /></span>
                                    <InputText type="password"
                                        placeholder="Confirme a senha"
                                        size="100"
                                        name="confirmaSenha"
                                        value={this.state.confirmaSenha}
                                        onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="p-col-11">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="pi pi-list" /></span>
                                    <Dropdown
                                        name="tipo"
                                        options={this.state.tipos}
                                        className="p-dropdown"
                                        value={this.state.tipo}
                                        onChange={event => this.setState({ tipo: event.value })}
                                        autoWidth={false} />
                                 </div>       
                            </div>
                            <div className="p-col-12">
                                <Button type="button" label="Criar" icon="pi pi-save" iconPos="left" className="p-button-raised p-button-rounded" disabled={statusBotao} onClick={this.registrar} />
                            </div>
                            <div className="p-col-12">
                                <Link to={{ pathname: 'login' }}>
                                    <Button type="button" icon="pi pi-arrow-left" iconPos="left" className="p-button-raised p-button-rounded p-button-secondary" label="Voltar" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}
export default withRouter(Registro);