import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import '../../App.scss';
import { Event } from '../../service/analytics';
import api from '../../service/api';
import { getRole, isAuthenticated, login, logout } from '../../service/auth';
import { Role } from '../../service/role';

export class Login extends Component {
    state = {
        redirect: false
    }
    constructor() {
        super();
        this.state = {
            email: '',
            senha: '',
            loading: false,
            statusBtnLogin: false
        };
    }
    componentDidMount() {
        if (isAuthenticated()) {
            logout();
        }
        this.initializeReactGA();
    }
    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/login');
    }
    handleChange = (event) => {
        const { target: { name, value } } = event
        this.setState({ [name]: value })
    }
    login = async (e) => {
        e.preventDefault();
        Event("Geral", "Logando no site", "Botao Login acionado");
        if (this.validaForm()) {
            this.setState({ loading: true });
            this.setState({ statusBtnLogin: true });
            let user = {
                username: this.state.email,
                password: this.state.senha
            }
            try {
                const response = await api.post("/login", user);
                if (response.status === 200 && response.data.token) {
                    login(response.data.token);
                    const role = getRole();
                    let url;
                    if (role && role[0] === Role.User) {
                        url = "/dashboard";
                    } else {
                        url = "/dashboardEmpresa";
                    }
                    if (role && role[1] === Role.Admin) {
                        url = "/dashboardAdmin";
                    }
                    this.props.history.push(url);
                    window.location.reload();
                } else {
                    this.setState({ statusBtnLogin: false });
                    setTimeout(() => {
                        this.growl.show({ severity: 'error', summary: 'Resultado', detail: 'Usuário inválido' });
                    }, 2000);
                }
            } catch (e) {
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.setState({ statusBtnLogin: false });
                    this.growl.show({ severity: 'error', summary: 'Resultado', detail: 'Usuário inválido' });
                }, 2000);
            } finally {
                setTimeout(() => {
                    this.setState({ loading: false });
                }, 2000);
            }
        }
    }

    limpar = () => {
        this.setState({ email: '' });
        this.setState({ senha: '' });
    }

    validaForm = () => {
        let isValid = true;
        let erros = [];
        if (!this.state.email) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'Email é obrigatório' });
            isValid = false;
        }
        if (!this.state.senha) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'Senha é obrigatória' });
            isValid = false;
        }
        if (erros.length) {
            this.growl.show(erros);
        }
        return isValid;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    render() {
        const { loading } = this.state;
        const statusBotao = this.state.statusBtnLogin;
        let dev = "assets/layout/images/dev_7.jpg";
        let css = this.isDesktop() ? "p-col-6 p-md-6 p-lg-6 p-fluid content" : "p-col-10 p-md12 p-lg-10 p-fluid content"
        let isFirefox = typeof InstallTrigger !== 'undefined';
        let tamanhoColuna = 'p-col-12';
        if (isFirefox) {
            tamanhoColuna = 'p-col-11';
        }
        return (
            <div className="bg-dim full-bg-size" style={{
                backgroundImage: `url(${dev})`, display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderradius: '50%'
            }}>
                {loading && <img src="assets/layout/images/preloader.gif" width="35" alt="Carregando" />}
                <Growl ref={(el) => this.growl = el} />
                <div className={css} >
                    <div className="login-form">
                        <h5>Login</h5>
                        <div className="p-col-12">
                            <div className={tamanhoColuna}>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="pi pi-envelope" /></span>
                                    <InputText type="text"
                                        placeholder="Email"
                                        name="email"
                                        size="100"
                                        value={this.state.email}
                                        onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className={tamanhoColuna}>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="pi pi-key" /></span>
                                    <InputText type="password"
                                        placeholder="Senha"
                                        size="100"
                                        name="senha"
                                        value={this.state.senha}
                                        onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="p-col-12">
                                <Button type="button" label="Entrar" className="p-button-raised p-button-rounded" disabled={statusBotao} icon="pi pi-check" iconPos="left" onClick={this.login} />
                            </div>
                            <div className="p-col-12">
                                <Link to={{ pathname: 'registrar' }}>
                                    <Button type="button" className="p-button-raised p-button-rounded p-button-secondary" label="Registrar" icon="pi pi-user-plus" iconPos="left" disabled={statusBotao} />
                                </Link>
                            </div>
                            <div className="p-col-12">
                                <Link to={{ pathname: 'esqueci-senha' }}>
                                    <h6>Esqueceu sua senha?</h6>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}