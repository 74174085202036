import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Panel } from 'primereact/panel';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { Event } from '../../service/analytics';
import api from '../../service/api';
import { getRole, getUser, isAuthenticated } from '../../service/auth';
import { Role } from '../../service/role';

export class Dashboard extends Component {
    constructor() {
        super();
        this.state = {
            countIndicacoes: null,
            countVagas: null,
            countEmpresas: null,
            descricaoVaga: null,
            visible: false,
            vaga: {},
            devs: [],
            vagas: [],
            indicacoes: [],
            empresas: [],
            role: null,
            loading: false
        }
    }
    componentDidMount() {
        const role = getRole();
        this.setState({ role: role[0] });
        if (!isAuthenticated() || role[0] !== Role.User) {
            window.location.href = `/#/login`;
        }
        this.initializeReactGA();
        this.counts();
        this.cardDevs();
        this.cardEmpresas();
        this.cardVagas();
        this.getPerfil();
        this.getDevByEmail();
    }
    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/dashboard');
    }
    getDevByEmail = async () => {
        const user = JSON.parse(getUser());
        const email = user ? user.sub : "";
        try {
            await api.get(`/desenvolvedores/find/${email}`);
        } catch (err) {
            if ((err !== undefined && err !== "undefined") && (err.response && err.response.status === 404)) {
                this.growl.show({ severity: 'warn', summary: 'Informação', detail: 'Preencha o seu perfil de Desenvolvedor' });
                setTimeout(() => {
                    window.location.href = "/#/desenvolvedor";
                }, 1000);
            }
        }
    }
    getPerfil = () => {
        const role = getRole();
        this.setState({ role: role });
    }

    counts = async () => {
        try {
            this.setState({ loading: true });
            const response = await api.get('/dashboard/counts');
            this.setState({ countIndicacoes: response.data[0].countIndicacao });
            this.setState({ countVagas: response.data[0].countVagas });
            this.setState({ countEmpresas: response.data[0].countEmpresas });
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({ loading: false });
        }
    }


    cardDevs = async () => {
        try {
            this.setState({ loading: true });
            const response = await api.get('/dashboard/devs');
            this.setState({ devs: response.data });
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({ loading: false });
        }
    }

    cardVagas = async () => {
        try {
            this.setState({ loading: true });
            const response = await api.get('/dashboard/vagas');
            this.setState({ vagas: response.data });
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({ loading: false });
        }
    }

    cardEmpresas = async () => {
        try {
            this.setState({ loading: true });
            const response = await api.get('/dashboard/empresas');
            this.setState({ empresas: response.data });
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({ loading: false });
        }
    }

    getVagaById = async (id) => {
        Event("Geral", "Ver vaga", "Link ver vaga acionado");
        const response = await api.get('/vagas/' + id);
        const vag = response.data;
        this.setState({ vaga: vag });
        let newText = vag.descricao.split('\n').map((item, i) => <p key={i}>{item}</p>);
        this.setState({ descricaoVaga: newText });
    }

    showVaga = (e) => {
        Event("Desenvolvedores", "Exibir vaga", "Link vaga acionado");
        const idVaga = e.data.id;
        this.getVagaById(idVaga);
        this.setState({ visible: true });
    }

    showVagas = () => {
        Event("Desenvolvedores", "Ver vagas", "Link ver vagas acionado");
        window.location.href = `/#/findVagas`;
    }

    showVagasEmpresas = () => {
        Event("Desenvolvedores", "Ver vagas das empresas", "Link ver vagas empresas acionado");
        window.location.href = `/#/empresas`;
    }

    novaIndicacao() {
        Event("Desenvolvedores", "Nova indicacao", "Link nova indicacao acionado");
        window.location.href = `/#/indicacao`;
    }
    isDesktop() {
        return window.innerWidth > 1024;
    }
    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-search" className="p-button-raised p-button-rounded p-button-success"></Button>
        </div>;
    }
    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-times" className="p-button-raised p-button-rounded p-button-danger" onClick={() => this.setState({ visible: false })} label="Fechar" />
            </div>
        );
        var vagasCount = this.state.vagas ? this.state.vagas.length : 0;
        var footer = "Novas " + vagasCount + ' vagas';
        const { loading } = this.state;
        return (
            <div className="p-grid p-fluid dashboard">
                <Growl ref={(el) => this.growl = el} />
                {loading &&
                    <div className="p-col-12 p-lg-12">
                        <img src="assets/layout/images/preloader.gif" width="35" alt="Carregando" />
                    </div>
                }
                <div className="p-col-12 p-lg-4">
                    <div className="card-vagas summary" onClick={this.showVagas}>
                        <span className="title">Vagas</span>
                        <span className="detail">Número de vagas</span>
                        <span className="count vagas">{this.state.countVagas}</span>
                    </div>
                </div>
                <div className="p-col-12 p-lg-4">
                    <div className="card-indicacoes summary" onClick={this.showVagasEmpresas}>
                        <span className="title">Empresas</span>
                        <span className="detail">Número de empresas</span>
                        <span className="count indicacoes">{this.state.countEmpresas}</span>
                    </div>
                </div>
                <div className="p-col-12 p-lg-4">
                    <div className="card-devs summary" onClick={this.novaIndicacao}>
                        <span className="title">Indicações</span>
                        <span className="detail">Número de indicações</span>
                        <span className="count devs">{this.state.countIndicacoes}</span>
                    </div>
                </div>
                <div className="p-col-12 p-lg-6 contacts">
                    <Panel header="Novas Empresas">
                        <ul>
                            {
                                this.state.empresas && this.state.empresas.map((empresa, index) => {
                                    let nome = empresa.nome.length > 30 ? empresa.nome.substring(0, 30) + "..." : empresa.nome;
                                    let email = empresa.emailResponsavel.length > 40 ? empresa.emailResponsavel.substring(0, 40) : empresa.emailResponsavel;
                                    let avatar = "assets/layout/images/avatar_4.png";
                                    return (
                                        <li key={empresa.id}>
                                            <Link to={{ pathname: `vagaEmpresa/${nome}` }}>
                                                <button className="p-link" id={index}>
                                                    <img src={avatar} width="35" alt="avatar1" />
                                                    <span className="name">{nome}</span>
                                                    <span className="email">{email}</span>
                                                </button>
                                            </Link>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </Panel>
                </div>

                <div className="p-col-12 p-lg-6 contacts">
                    <Panel header="Novos Profissionais">
                        <ul>
                            {
                                this.state.devs && this.state.devs.map((dev, index) => {
                                    const sexo = `${dev.sexo}`;
                                    let nome = dev.nome.length > 30 ? dev.nome.substring(0, 30) + "..." : dev.nome;
                                    let email = dev.email.length > 40 ? dev.email.substring(0, 40) : dev.email;
                                    let avatar = "assets/layout/images/avatar_2.png";
                                    if (sexo === 'F') {
                                        avatar = "assets/layout/images/avatar_1.png";
                                    }
                                    return (
                                        <li key={dev.id}>

                                            <button className="p-link" id={index}>
                                                <img src={avatar} width="35" alt="avatar1" />
                                                <span className="name">{nome}</span>
                                                <span className="email">{email}</span>
                                            </button>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </Panel>
                </div>
                <div className="p-col-12 p-lg-12">
                    <div className="card">
                        <h1 style={{ fontSize: '16px' }}>Novas 20 Vagas</h1>
                        <DataTable value={this.state.vagas} style={{ marginBottom: '20px' }} responsive={true}
                            selectionMode="single" onRowClick={this.showVaga} paginator={true} rows={5} footer={footer} >
                            <Column field="titulo" header="Vaga" sortable={true} />
                            <Column field="empresa" header="Empresa" sortable={true} />
                            <Column field="cidade" header="Cidade" sortable={true} style={{ width: '15%' }} />
                            <Column field="dataCriacao" header="Data" sortable={true} style={{ width: '12%' }} />
                            <Column body={this.actionTemplate} style={{ textAlign: 'center', width: '6em' }} />
                        </DataTable>
                    </div>
                    <Dialog header="Exibir Vaga" footer={dialogFooter} visible={this.state.visible} style={{ width: this.isDesktop() ? '60vw' : '100vw' }} modal={true} onHide={() => this.setState({ visible: false })}>
                        <div className="p-col-12 p-lg-12">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-12">
                                    <Panel header="Dados da Empresa">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="cb2"><b>Empresa:</b> {this.state.vaga.empresa}</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="cb2"><b>Email:</b><a href={`mailto:${this.state.vaga.emailReponsavel}`}> {this.state.vaga.emailReponsavel} </a> </label>
                                        </div>
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-md-12">
                                    <Panel header="Detalhes da Vaga">

                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="cb2"><b>Título da Vaga:</b> {this.state.vaga.titulo}</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="cb2"><b>Cargo:</b> {this.state.vaga.cargo}</label>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <label htmlFor="cb2"><b>Tipo:</b> {this.state.vaga.tipo}</label>
                                        </div>
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-md-12">
                                    <Panel header="Localidade da Vaga">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="cb2"><b>Estado: </b> {this.state.vaga.estado}</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="cb2"><b>Cidade: </b> {this.state.vaga.cidade}</label>
                                        </div>
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-md-12">
                                    <Panel header="Descrição da Vaga">
                                        {this.state.descricaoVaga}
                                    </Panel>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }
}