import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { Component } from 'react';
import api from '../../service/api';
import { isAuthenticated,getRole,getUser } from '../../service/auth';
import { Role } from '../../service/role';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga';
import { Event } from '../../service/analytics';

export class UpdateVaga extends Component {
    constructor() {
        super();
        this.state = {
            id: null,
            titulo: null,
            cargo: null,
            tipo: null,
            statusBtnSalvar: false,
            tipos: [
                { label: 'Informe o Tipo', value: null },
                { label: 'Home Office', value: 'Home Office' },
                { label: 'Presencial', value: 'Presencial' },
                { label: 'Hibrido', value: 'Hibrido' },
            ],
            cargos: [],
            estado: null,
            estados: [
                { label: 'Informe o Estado', value: null },
                { label: 'AC', value: 'AC' },
                { label: 'AL', value: 'AL' },
                { label: 'AP', value: 'AP' },
                { label: 'AM', value: 'AM' },
                { label: 'BA', value: 'BA' },
                { label: 'CE', value: 'CE' },
                { label: 'DF', value: 'DF' },
                { label: 'ES', value: 'ES' },
                { label: 'GO', value: 'GO' },
                { label: 'MA', value: 'MA' },
                { label: 'MT', value: 'MT' },
                { label: 'MS', value: 'MS' },
                { label: 'MG', value: 'MG' },
                { label: 'PA', value: 'PA' },
                { label: 'PB', value: 'PB' },
                { label: 'PR', value: 'PR' },
                { label: 'PE', value: 'PE' },
                { label: 'PI', value: 'PI' },
                { label: 'RJ', value: 'RJ' },
                { label: 'RN', value: 'RN' },
                { label: 'RS', value: 'RS' },
                { label: 'RO', value: 'RO' },
                { label: 'RR', value: 'RR' },
                { label: 'SC', value: 'SC' },
                { label: 'SE', value: 'SE' },
                { label: 'SP', value: 'SP' },
                { label: 'TO', value: 'TO' }
            ],
            cidade: null,
            descricao: null,
            empresa: null,
            codigoEmpresa: null,
            emailReponsavel: null
        }
    }
    componentDidMount() {
        var role = getRole();
        if (!isAuthenticated() || role[0] !== Role.Company) {
            window.location.href = `/#/login`;
        }
        const idVaga = Cookies.get("idVaga")
        this.getVagaById(idVaga);
        this.cargos();
        this.getEmpresaByEmail();
        this.initializeReactGA();
    }
    
    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/updateVaga');
    }
    
    getVagaById = async (id) => {
        try {
            const response = await api.get('/vagas/' + id);
            const vaga = response.data;
            this.setState({ id: vaga.id });
            this.setState({ estado: vaga.estado });
            this.setState({ cidade: vaga.cidade });
            this.setState({ titulo: vaga.titulo });
            this.setState({ cargo: vaga.cargo });
            this.setState({ tipo: vaga.tipo });
            this.setState({ descricao: vaga.descricao });
        } catch (err) {
            console.error(err);
        }
    }
    getEmpresaByEmail = async () => {
        const user = JSON.parse(getUser());
        const email = user ? user.sub : "";
        try {
            const response = await api.get(`/empresas/find/email/${email}`);
            this.setState({ empresa: response.data.nome });
            this.setState({ emailReponsavel: response.data.emailReponsavel });

        } catch (err) {
            if (err.response) {
                this.setState({ emailReponsavel: email });
            }
        }
    }
    cargos = async () => {
        const response = await api.get('/cargos/combo');
        this.setState({ cargos: response.data });
    };
    handleChange = (event) => {
        const { target: { name, value } } = event
        this.setState({ [name]: value })
    }
    voltar() {
        window.history.go(-1);
    }
    salvar = async (e) => {
        e.preventDefault();
        Event("Empresas","Atualizar Vaga","Botao salvar acionado");
        if (this.validaForm()) {
            this.setState({ statusBtnSalvar: true });
            let vaga = {
                id: this.state.id,
                descricao: this.state.descricao,
                empresa: this.state.empresa,
                cargo: this.state.cargo,
                titulo: this.state.titulo,
                tipo: this.state.tipo,
                estado: this.state.estado,
                cidade: this.state.cidade,
                emailReponsavel: this.state.emailReponsavel
            };
            try {
                const response = await api.put(`/vagas/${vaga.id}`, vaga)
                if (response.status === 200) {
                    this.growl.show({ severity: 'success', summary: 'Successo', detail: 'Vaga alterada com sucesso' });
                    setTimeout(() => {
                        window.location.href = "/#/vagaEmpresa";
                    }, 2000);
                }
            } catch (e) {
                this.setState({ statusBtnSalvar: false });
                this.growl.show({ severity: 'error', summary: 'Erro', detail: 'Não foi possível alterar a Vaga' });
            }
        }
    }

    validaForm = () => {
        const tamanhoTitulo = this.state.titulo ? this.state.titulo.length : 0;
        const tamanhoDescricao = this.state.descricao ? this.state.descricao.length : 0;
        const tamanhoCidade = this.state.cidade ? this.state.cidade.length : 0;
       
        let isValid = true;
        let erros = [];
        if (!this.state.empresa) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'Empresa é obrigatória' });
            isValid = false;
        }
        if (!this.state.emailReponsavel) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'Email do responsável é obrigatório' });
            isValid = false;
        }
        if (!this.state.estado) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'Estado é obrigatório' });
            isValid = false;
        }
        if (tamanhoCidade < 3) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'Cidade inválida' });
            isValid = false;
        }
        if (tamanhoTitulo < 5) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'Título da Vaga deve conter no mínimo 5 caracteres' });
            isValid = false;
        }
        if (!this.state.cargo) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'Cargo da Vaga é obrigatório' });
            isValid = false;
        }
        if (!this.state.tipo) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'Tipo da Vaga é obrigatório' });
            isValid = false;
        }
        if (tamanhoDescricao < 20) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'Descrição da Vaga deve conter no mínimo 20 caracteres' });
            isValid = false;
        }
        if (erros.length) {
            this.growl.show(erros);
        }
        return isValid;
    }
    render() {
        const statusBotao = this.state.statusBtnSalvar;
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <h1>Atualizar Vaga</h1>
                        <div className="p-grid p-fluid">
                            <Growl ref={(el) => this.growl = el} />
                            <div className="p-col-12 p-lg-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6">
                                        <InputText
                                            name="empresa"
                                            value={this.state.empresa}
                                            disabled
                                            placeholder="Informe a Empresa"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <InputText
                                            placeholder="Email Responsável"
                                            name="emailReponsavel"
                                            disabled
                                            value={this.state.emailReponsavel}
                                            readOnly
                                            maxLength="40"
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <Dropdown
                                            name="estado"
                                            filter={true}
                                            options={this.state.estados}
                                            value={this.state.estado}
                                            onChange={event => this.setState({ estado: event.value })}
                                            autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText
                                            placeholder="Cidade"
                                            name="cidade"
                                            maxLength="60"
                                            value={this.state.cidade}
                                            onChange={this.handleChange} />
                                    </div>

                                    <div className="p-col-12 p-md-5">
                                        <InputText
                                            placeholder="Título da Vaga"
                                            name="titulo"
                                            value={this.state.titulo}
                                            maxLength="60"
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown
                                            name="cargo"
                                            options={this.state.cargos}
                                            value={this.state.cargo}
                                            filter={true}
                                            filterPlaceholder="Informe a Cargo"
                                            placeholder="Informe o Cargo"
                                            onChange={event => this.setState({ cargo: event.value })}
                                            autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <Dropdown
                                            name="tipo"
                                            options={this.state.tipos}
                                            value={this.state.tipo}
                                            filter={true}
                                            filterPlaceholder="Informe o Tipo"
                                            placeholder="Informe a Tipo"
                                            onChange={event => this.setState({ tipo: event.value })}
                                            autoWidth={false} />
                                    </div>

                                    <div className="p-col-12 p-md-12">
                                        <InputTextarea
                                            rows={10}
                                            cols={30}
                                            autoResize={true}
                                            value={this.state.descricao}
                                            placeholder="Informe a Descrição"
                                            name="descricao"
                                            onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-6 p-md-3" style={{ align: 'right' }}>
                                <Button label="Salvar"  icon="pi pi-save" iconPos="left" disabled={statusBotao} className="p-button-raised p-button-rounded p-button-primary" style={{ marginBottom: '10px' }} onClick={this.salvar} />
                            </div>
                            <div className="p-col-6 p-md-3" style={{ align: 'right' }}>
                                <Button label="Voltar" icon="pi pi-arrow-left" iconPos="left" className="p-button-raised p-button-rounded p-button-secondary" style={{ marginBottom: '10px' }} onClick={this.voltar} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}