import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import api from '../../service/api';
import { getRole, isAuthenticated, getUser } from '../../service/auth';
import { Role } from '../../service/role';
import Cookies from 'js-cookie'
import ReactGA from 'react-ga';
import { Event } from '../../service/analytics';

export class DashboardEmpresa extends Component {
    constructor() {
        super();
        this.state = {
            countDevs: null,
            countIndicacoes: null,
            countVagas: null,
            countEmpresas: null,
            empresa: null,
            devs: [],
            vagas: [],
            indicacoes: [],
            empresas: [],
            role: null,
            loading: false
        }
    }
    componentDidMount() {
        const role = getRole();
        this.setState({ role: role[0] });
        if (!isAuthenticated() || role[0] !== Role.Company) {
            window.location.href = `/#/login`;
        }
        this.getEmpresaByEnail();
        this.counts();
        this.cardDevs();
        this.cardIndicacoes();
        this.cardEmpresas();
        this.getPerfil();
        this.initializeReactGA();
    }
    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/dashboardEmpresa');
    }
    getPerfil = () => {
        const role = getRole();
        this.setState({ role: role });
    }

    counts = async () => {
        try {
            this.setState({ loading: true });
            const response = await api.get('/dashboard/counts');
            this.setState({ countDevs: response.data[0].countDevs });
            this.setState({ countIndicacoes: response.data[0].countIndicacao });
            this.setState({ countEmpresas: response.data[0].countEmpresas });
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({ loading: false });
        }
    }

    cardDevs = async () => {
        try {
            this.setState({ loading: true });
            const response = await api.get('/dashboard/devs');
            this.setState({ devs: response.data });
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({ loading: false });
        }
    }

    cardVagas = async (empresa) => {
        try {
            this.setState({ loading: true });
            const response = await api.get(`/vagas/search/${empresa}`);
            this.setState({ vagas: response.data });
            this.setState({ countVagas: this.state.vagas.length });
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({ loading: false });
        }
    }

    getEmpresaByEnail = async () => {
        const user = JSON.parse(getUser());
        const email = user ? user.sub : "";
        api.get(`/empresas/find/email/${email}`)
            .then(res => {
                if (res.status === 200) {
                    this.cardVagas(res.data.nome);
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(error => {
                this.setState({ loading: false });
            });
    }

    cardIndicacoes = async () => {
        try {
            this.setState({ loading: true });
            const response = await api.get('/dashboard/indicacoes');
            this.setState({ indicacoes: response.data });
        } catch (err) {
            console.info(err);
        } finally {
            this.setState({ loading: false });
        }
    }
    cardEmpresas = async () => {
        try {
            this.setState({ loading: true });
            const response = await api.get('/dashboard/empresas');
            this.setState({ empresas: response.data });
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({ loading: false });
        }
    }

    showVaga(e) {
        Event("Empresas","Exibir Vaga","Link ver vaga acionado");
        const idVaga = e.data.id;
        Cookies.set("idVaga", idVaga);
        window.location.href = `/#/showVagaEmpresa`;
    }

    showDevs = () => {
        Event("Empresas","Buscar Desenvolvedores","Link para buscar devs acionado");
        window.location.href = `/#/findProfissionais`;
    }
    showVagas = () => {
        Event("Empresas","Exibir suas vagas","Link ver suas vagas acionado");
        window.location.href = `/#/vagaEmpresa`;
    }

    novaIndicacao() {
        Event("Empresas","Nova indicacao","Link para nova indicacao");
        window.location.href = `/#/indicacao`;
    }
    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-search" className="p-button-raised p-button-rounded p-button-success"></Button>
        </div>;
    }
    render() {
        var vagasCount = this.state.vagas ? this.state.vagas.length : 0;
        var footer = "Total de  " + vagasCount + ' vagas';
        const { loading } = this.state;
        return (
            <div className="p-grid p-fluid dashboard">
                <Growl ref={(el) => this.growl = el} />
                {loading &&
                    <div className="p-col-12 p-lg-12">
                        <img src="assets/layout/images/preloader.gif" width="35" alt="Carregando" />
                    </div>
                }
                <div className="p-col-12 p-lg-4">
                    <div className="card-devs summary" onClick={this.showDevs}>
                        <span className="title">Profissionais</span>
                        <span className="detail">Número de profissionais</span>
                        <span className="count devs">{this.state.countDevs}</span>
                    </div>
                </div>
                <div className="p-col-12 p-lg-4">
                    <div className="card-indicacoes summary" onClick={this.novaIndicacao}>
                        <span className="title">Indicações</span>
                        <span className="detail">Número de indicações</span>
                        <span className="count indicacoes">{this.state.countIndicacoes}</span>
                    </div>
                </div>
                <div className="p-col-12 p-lg-4">
                    <div className="card-vagas summary" onClick={this.showVagas}>
                        <span className="title">Suas Vagas</span>
                        <span className="detail">Número de vagas</span>
                        <span className="count vagas">{this.state.countVagas}</span>
                    </div>
                </div>

                <div className="p-col-12 p-lg-6 contacts">
                    <Panel header="Novos Profissionais">
                        <ul>
                            {
                                this.state.devs && this.state.devs.map((dev, index) => {
                                    const sexo = `${dev.sexo}`;
                                    let avatar = "assets/layout/images/avatar_2.png";
                                    if (sexo === 'F') {
                                        avatar = "assets/layout/images/avatar_1.png";
                                    }
                                    return (
                                        <li key={dev.id}>
                                            <Link Link to={{ pathname: `showDesenvolvedor/${dev.id}` }}>
                                                <button className="p-link" id={index}>
                                                    <img src={avatar} width="35" alt="avatar1" />
                                                    <span className="name">{dev.nome}</span>
                                                    <span className="email">{dev.email}</span>
                                                </button>
                                            </Link>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </Panel>
                </div>

                <div className="p-col-12 p-lg-6 contacts">
                    <Panel header="Novas Indicações">
                        <ul>
                            {
                                this.state.indicacoes && this.state.indicacoes.map((indicacao, index) => {
                                    const sexo = `${indicacao.sexo}`;
                                    let avatar = `assets/layout/images/avatar_4.png`;
                                    if (sexo === 'F') {
                                        avatar = `assets/layout/images/avatar_1.png`;
                                    }
                                    return (
                                        <li key={indicacao.id}>
                                            <Link to={{ pathname: `showIndicacao/${indicacao.id}` }}>
                                                <button className="p-link">
                                                    <img src={avatar} width="35" alt="avatar1" />
                                                    <span className="name">{indicacao.nome}</span>
                                                    <span className="email">{indicacao.email}</span>
                                                </button>
                                            </Link>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </Panel>
                </div>
                <div className="p-col-12 p-lg-12">
                    <div className="card">
                        <h1 style={{ fontSize: '16px' }}>Suas vagas</h1>
                        <DataTable value={this.state.vagas} emptyMessage="Nenhum registro encontrado" style={{ marginBottom: '20px' }} responsive={true}
                            selectionMode="single" onRowClick={this.showVaga} paginator={true} rows={5} footer={footer} >
                            <Column field="empresa" header="Empresa" sortable={true} />
                            <Column field="titulo" header="Vaga" sortable={true} />
                            <Column field="cidade" header="Cidade" sortable={true} style={{ width: '15%' }} />
                            <Column field="dataCriacao" header="Data" sortable={true} style={{ width: '12%' }} />
                            <Column body={this.actionTemplate} style={{textAlign:'center', width: '6em'}}/>
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }
}