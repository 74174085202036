import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import api from '../../service/api';
import { Role } from '../../service/role';
import { isAuthenticated, getRole, getUser } from '../../service/auth';
import Cookies from 'js-cookie'
import ReactGA from 'react-ga';
import { Event } from '../../service/analytics';

export class BuscaVagas extends Component {

    constructor() {
        super();
        this.state = {
            vagas: [],
            cargo: null,
            descricao: null,
            descricaoVaga: null,
            vaga: {},
            tipo: null,
            visible: false,
            loading: false,
            statusBtnBuscar: false,
            estado: null,
            tipos: [
                { label: 'Informe o Tipo', value: null },
                { label: 'Home Office', value: 'Home Office' },
                { label: 'Presencial', value: 'Presencial' },
                { label: 'Hibrido', value: 'Hibrido' },
            ],
            estados: [
                { label: 'Informe o Estado', value: null },
                { label: 'AC', value: 'AC' },
                { label: 'AL', value: 'AL' },
                { label: 'AP', value: 'AP' },
                { label: 'AM', value: 'AM' },
                { label: 'BA', value: 'BA' },
                { label: 'CE', value: 'CE' },
                { label: 'DF', value: 'DF' },
                { label: 'ES', value: 'ES' },
                { label: 'GO', value: 'GO' },
                { label: 'MA', value: 'MA' },
                { label: 'MT', value: 'MT' },
                { label: 'MS', value: 'MS' },
                { label: 'MG', value: 'MG' },
                { label: 'PA', value: 'PA' },
                { label: 'PB', value: 'PB' },
                { label: 'PR', value: 'PR' },
                { label: 'PE', value: 'PE' },
                { label: 'PI', value: 'PI' },
                { label: 'RJ', value: 'RJ' },
                { label: 'RN', value: 'RN' },
                { label: 'RS', value: 'RS' },
                { label: 'RO', value: 'RO' },
                { label: 'RR', value: 'RR' },
                { label: 'SC', value: 'SC' },
                { label: 'SE', value: 'SE' },
                { label: 'SP', value: 'SP' },
                { label: 'TO', value: 'TO' }
            ],
            cargos: []
        };
    }

    componentDidMount() {
        var role = getRole();
        if (!isAuthenticated() || role[0] !== Role.User) {
            window.location.href = `/#/login`;
            window.location.reload();
        }
        this.initializeReactGA();
        this.cargos();
        if (role[0] !== "" && !role[1]) {
            this.getDevByEmail();
        }
    }

    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/showVaga');
    }
    getDevByEmail = async () => {
        const user = JSON.parse(getUser());
        const email = user ? user.sub : "";
        try {
            await api.get(`/desenvolvedores/find/${email}`);
        } catch (err) {
            if ((err !== undefined && err !== "undefined") && (err.response && err.response.status === 404)) {
                this.growl.show({ severity: 'warn', summary: 'Informação', detail: 'Preencha o seu perfil' });
                setTimeout(() => {
                    window.location.href = "/#/desenvolvedor";
                }, 1000);
            }
        }
    }
    voltar = (e) =>{
        e.preventDefault();
        window.history.go(-1);
    }
    cargos = async () => {
        const response = await api.get('/cargos/combo');
        this.setState({ cargos: response.data });
    }
    findVagas = async (e) => {
        e.preventDefault();
        this.setState({ vagas: [] });
        Event("Desenvolvedores", "Buscar Vagas", "Botao buscar vagas acionado");
        this.setState({ loading: true });
        this.setState({ statusBtnBuscar: true });

        try {
            let filtroVaga = {
                descricao: this.state.descricao === '' ? null : this.state.descricao,
                estado: this.state.estado,
                cargo: this.state.cargo,
                tipo: this.state.tipo
            };
            const response = await api.post('/vagas/search', filtroVaga);

            if (!response.data.length) {
                this.growl.show({ severity: 'error', summary: 'Resultado', detail: 'Nenhuma vaga encontrada' });
            }
            const vagas = response.data;
            this.setState({ vagas: vagas });
            this.setState({ statusBtnBuscar: false });
        } catch (err) {
            this.setState({ statusBtnBuscar: false });
            this.growl.show({ severity: 'error', summary: 'Resultado', detail: 'Nenhuma vaga encontrada' });
        } finally {
            this.setState({ loading: false });
        }
    }
    getVagaById = async (id) => {
        Event("Geral","Ver vaga","Link ver vaga acionado");
        const response = await api.get('/vagas/' + id);
        const vag = response.data;
        this.setState({ vaga: vag });
        let newText = vag.descricao.split('\n').map((item, i) => <p key={i}>{item}</p>);
        this.setState({ descricaoVaga: newText });
    }

    showVaga = (e) => {
        Event("Desenvolvedores", "Exibir vaga", "Link vaga acionado");
        const idVaga = e.data.id;
        this.getVagaById(idVaga);
        this.setState({ visible: true });
    }

    handleChange = (event) => {
        const { target: { name, value } } = event
        this.setState({ [name]: value })
    }

    limpar = (e) => {
        this.setState({ vagas: null });
        this.setState({ cargo: null });
        this.setState({ tipo: null });
        this.setState({ estado: null });
        this.setState({ descricao: '' });
        Cookies.remove("vagas");

    }
    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-search" className="p-button-raised p-button-rounded p-button-success"></Button>
        </div>;
    }
    
    isDesktop() {
        return window.innerWidth > 1024;
    }
    
    render() {
        const dialogFooter = (
            <div >
                <Button icon="pi pi-times" className="p-button-raised p-button-rounded p-button-danger" onClick={() => this.setState({ visible: false })} label="Fechar" />
            </div>
        );
        var vagasCount = this.state.vagas ? this.state.vagas.length : 0;
        var footer = "Total de  " + vagasCount + ' vagas';
        const statusBotao = this.state.statusBtnBuscar;
        const { loading } = this.state;
        return (
            <div className="p-grid">
                {loading && <img src="assets/layout/images/preloader.gif" width="35" alt="Carregando" />}
                <div className="p-col-12">
                    <div className="card">
                        <h1>Busca de Vagas</h1>
                        <div className="p-grid p-fluid">
                            <Growl ref={(el) => this.growl = el} />
                            <div className="p-col-12 p-lg-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-8">
                                        <InputText
                                            placeholder="Descrição de Vaga"
                                            name="descricao"
                                            value={this.state.descricao}
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown
                                            name="estado"
                                            filter={true}
                                            options={this.state.estados}
                                            value={this.state.estado}
                                            onChange={event => this.setState({ estado: event.value })}
                                            autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <Dropdown
                                            name="cargo"
                                            options={this.state.cargos}
                                            value={this.state.cargo}
                                            filter={true}
                                            filterPlaceholder="Informe o Cargo"
                                            placeholder="Informe o Cargo"
                                            onChange={event => this.setState({ cargo: event.value })}
                                            autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown
                                            name="tipo"
                                            options={this.state.tipos}
                                            value={this.state.tipo}
                                            filter={true}
                                            filterPlaceholder="Informe o Tipo"
                                            placeholder="Informe o Tipo"
                                            onChange={event => this.setState({ tipo: event.value })}
                                            autoWidth={false} />
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-6 p-md-3" style={{ align: 'right' }}>
                                <Button label="Buscar" icon="pi pi-search" iconPos="left" disabled={statusBotao} className="p-button-raised p-button-rounded p-button-primary" style={{ marginBottom: '10px' }} onClick={this.findVagas} />
                            </div>
                            <div className="p-col-6 p-md-3" style={{ align: 'right' }}>
                                <Button label="Limpar" icon="pi pi-undo" iconPos="left" className="p-button-raised p-button-rounded p-button-secondary" style={{ marginBottom: '10px' }} onClick={this.limpar} />
                            </div>
                            <div className="p-col-6 p-md-3" style={{ align: 'right' }}>
                                <Button label="Voltar" icon="pi pi-arrow-left" iconPos="left" className="p-button-raised p-button-rounded p-button-danger" style={{ marginBottom: '10px' }} onClick={this.voltar} />
                            </div>
                            {vagasCount > 0 &&
                                <div className="p-col-12 p-lg-12">
                                    <DataTable value={this.state.vagas} emptyMessage="Nenhum registro encontrado" style={{ marginBottom: '20px' }} responsive={true}
                                        selectionMode="single" onRowClick={this.showVaga} paginator={true} rows={10} footer={footer} >
                                        <Column field="titulo" header="Vaga" sortable={true} />
                                        <Column field="empresa" header="Empresa" sortable={true} />
                                        <Column field="tipo" header="Tipo" sortable={true} style={{ width: '12%' }} />
                                        <Column field="cidade" header="Cidade" sortable={true} style={{ width: '15%' }} />
                                        <Column field="dataCriacao" header="Data" sortable={true} style={{ width: '10%' }} />
                                        <Column body={this.actionTemplate} style={{ textAlign: 'center', width: '4em' }} />
                                    </DataTable>
                                </div>
                            }
                            <Dialog header="Exibir Vaga" footer={dialogFooter} visible={this.state.visible} style={{ width: this.isDesktop() ? '60vw' : '100vw' }} modal={true} onHide={() => this.setState({ visible: false })}>
                                <div className="p-col-12 p-lg-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-12">
                                            <Panel header="Dados da Empresa">
                                                <div className="p-col-12 p-md-12">
                                                    <label htmlFor="cb2"><b>Empresa:</b> {this.state.vaga.empresa}</label>
                                                </div>
                                                <div className="p-col-12 p-md-12">
                                                    <label htmlFor="cb2"><b>Email:</b><a href={`mailto:${this.state.vaga.emailReponsavel}`}> {this.state.vaga.emailReponsavel} </a> </label>
                                                </div>
                                            </Panel>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Panel header="Detalhes da Vaga">

                                                <div className="p-col-12 p-md-12">
                                                    <label htmlFor="cb2"><b>Título da Vaga:</b> {this.state.vaga.titulo}</label>
                                                </div>
                                                <div className="p-col-12 p-md-12">
                                                    <label htmlFor="cb2"><b>Cargo:</b> {this.state.vaga.cargo}</label>
                                                </div>
                                                <div className="p-col-12 p-md-6">
                                                    <label htmlFor="cb2"><b>Tipo:</b> {this.state.vaga.tipo}</label>
                                                </div>
                                            </Panel>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Panel header="Localidade da Vaga">
                                                <div className="p-col-12 p-md-12">
                                                    <label htmlFor="cb2"><b>Estado: </b> {this.state.vaga.estado}</label>
                                                </div>
                                                <div className="p-col-12 p-md-12">
                                                    <label htmlFor="cb2"><b>Cidade: </b> {this.state.vaga.cidade}</label>
                                                </div>

                                            </Panel>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Panel header="Descrição da Vaga">
                                                {this.state.descricaoVaga}
                                            </Panel>
                                        </div>
                                    </div>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}