import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import api from '../../service/api';
import { isAuthenticated, getRole, getUser } from '../../service/auth';
import { Role } from '../../service/role';
import Cookies from 'js-cookie'
import ReactGA from 'react-ga';
export class ListEmpresas extends Component {

    constructor() {
        super();
        this.state = {
            empresas: [],
            empresasVagas: [],
            loading: false,
            layout: 'list',
            display: false
        };
    }
    componentDidMount() {
        var role = getRole();
        if (!isAuthenticated() || (role[1] !== Role.Admin && role[0] !== Role.User)) {
            window.location.href = `/#/login`;
        }
        this.initializeReactGA();
        if (role[0] !== "" && !role[1]) {
            this.getDevByEmail();
        }
        this.empresas();
    }

    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/empresas');
    }
    getDevByEmail = async () => {
        const user = JSON.parse(getUser());
        const email = user ? user.sub : "";
        try {
            await api.get(`/desenvolvedores/find/${email}`);
        } catch (err) {
            if ((err !== undefined && err !== "undefined") && (err.response && err.response.status === 404)) {
                this.growl.show({ severity: 'warn', summary: 'Informação', detail: 'Preencha o seu perfil de Desenvolvedor' });
                setTimeout(() => {
                    window.location.href = "/#/desenvolvedor";
                }, 1000);
            }
        }
    }

    empresas = async () => {
        try {
            this.setState({ loading: true });
            const response = await api.get('/empresas');
            this.setState({ empresas: response.data });
        } catch (err) {
            console.error(err);
        } finally {
            this.setState({ loading: false });
        }
    }

    vagas(e) {
        const email = e.data.email;
        Cookies.set("emailEmpresa", email);
        window.location.href = `/#/vagaEmpresa`;
    }

    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-search" className="p-button-raised p-button-rounded p-button-success"></Button>
        </div>;
    }

    voltar() {
        window.history.go(-1);
    }
    
    render() {
        var vagasCount = this.state.empresas ? this.state.empresas.length : 0;
        var footer = "Total de  " + vagasCount + ' empresas';
        const { loading } = this.state;
        return (
            <div className="p-grid">
                {loading && <img src="assets/layout/images/preloader.gif" width="35" alt="Carregando" />}
                <div className="p-col-12">
                    <div className="card">
                        <h1>Empresas com Vagas</h1>
                        <div className="p-grid p-fluid">
                            <Growl ref={(el) => this.growl = el} />
                            <div className="p-col-12 p-lg-12">
                                <DataTable value={this.state.empresas} selectionMode="single" paginator={true} rows={10}
                                    responsive={true} onRowClick={this.vagas} footer={footer}>
                                    <Column field="nome" header="Nome" sortable={true} />
                                    <Column field="email" header="Email Responsável" sortable={true} />
                                    <Column body={this.actionTemplate} style={{ textAlign: 'center', width: '6em' }} />
                                </DataTable>
                            </div>
                            <div className="p-col-6 p-md-3" style={{ align: 'rigth' }}>
                                <Button label="Voltar" icon="pi pi-arrow-left" iconPos="left" className="p-button-raised p-button-rounded p-button-danger" style={{ marginBottom: '10px' }} onClick={this.voltar} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}