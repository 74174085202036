import Cookies from 'js-cookie';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { Event } from '../../service/analytics';
import api from '../../service/api';
import { isAuthenticated } from '../../service/auth';

export class AlterarSenha extends Component {
    constructor() {
        super();
        this.state = {
            id: null,
            email: null,
            senha: null,
            confirmaSenha: null,
            loading: false,
            codigo: null,
            statusBtnSalvar: false
        }
    }
    componentDidMount() {
        if (isAuthenticated()) {
            window.location.href = `/#/dashboard`;
        }
        this.setState({ email: Cookies.get("email") });
        this.initializeReactGA();

    }
    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/alterar-senha');
    }
    getPerfilByEmail = async () => {
        const email = Cookies.get("email");
        try {
            const response = await api.get(`/usuarios/find/${email}`);
            if (response.status === 200) {
                this.setState({ id: response.data.id });
                this.setState({ email: response.data.email });
            }
        } catch (err) {
            console.error(err);
        }
    }
    cancelar() {
        window.location.href = "/#/login";
    }
    isDesktop() {
        return window.innerWidth > 1024;
    }
    handleChange = (event) => {
        const { target: { name, value } } = event
        this.setState({ [name]: value })
    }
    salvar = (e) => {
        e.preventDefault();
        Event("Alterar Senha", "Alterando a senha", "Botao Alterar");
        if (this.validaForm()) {
            this.setState({ loading: true });
            this.setState({ statusBtnSalvar: true });
            let resetSenha = {
                email: this.state.email,
                senha: this.state.senha,
                token: this.state.codigo
            };
            this.alterar(resetSenha);
        }
    }

    alterar = (resetSenha) => {
        api.put(`/resetSenha`, resetSenha)
            .then(res => {
                if (res.status === 200) {
                    this.growl.show({ severity: 'success', summary: 'Successo', detail: 'Senha alterada com sucesso' });
                    this.setState({ statusBtnSalvar: false });
                    setTimeout(() => {
                        this.setState({ loading: false });
                        window.location.href = `/#/login`;
                    }, 2000);
                }
            })
            .catch(error => {
                this.setState({ loading: false });
                this.setState({ statusBtnSalvar: false });
                this.growl.show({ severity: 'error', summary: 'Erro', detail: 'Não foi possível alterar a Senha' });
            });
    }

    validaForm = () => {
        let isValid = true;
        let erros = [];
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
        if (!this.state.codigo) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'Código é obrigatório' });
            isValid = false;
        }
        if (!strongRegex.test(this.state.senha)) {
            erros.push({ severity: 'error', summary: 'Senha inválida', detail: 'Sua deve conter no mínimo 1 caracter especial, 1 letra minuscula, 1 letra maiuscula e ter 8 caracteres' });
            isValid = false;
        }
        if (this.state.confirmaSenha !== this.state.senha) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'As senhas não conferem' });
            isValid = false;
        }
        if (erros.length) {
            this.growl.show(erros);
        }
        return isValid;
    }
    
    render() {
        const { loading } = this.state;
        const statusBotao = this.state.statusBtnSalvar;
        let dev = "assets/layout/images/dev_7.jpg";
        let css = this.isDesktop() ? "p-col-6 p-md-6 p-lg-6 p-fluid content" : "p-col-10 p-md12 p-lg-10 p-fluid content";
        let isFirefox = typeof InstallTrigger !== 'undefined';
        let tamanhoColuna = 'p-col-12';
        if (isFirefox) {
            tamanhoColuna = 'p-col-11';
        }

        return (

            <div className="bg-dim full-bg-size" style={{
                backgroundImage: `url(${dev})`, display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderradius: '10px'
            }}>

                <div className={css}>
                    {loading && <img src="assets/layout/images/preloader.gif" width="35" alt="Carregando" />}
                    <Growl ref={(el) => this.growl = el} />
                    <div className="alterar-senha-form">
                        <h5>Alterar a senha</h5>
                        <div className={tamanhoColuna}>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="pi pi-ticket" /></span>
                                <InputText type="text"
                                    placeholder="Codigo"
                                    name="codigo"
                                    size="100"
                                    value={this.state.codigo}
                                    onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className={tamanhoColuna}>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="pi pi-key" /></span>
                                <InputText type="password"
                                    placeholder="Senha"
                                    size="100"
                                    name="senha"
                                    value={this.state.senha}
                                    onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className={tamanhoColuna}>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="pi pi-key" /></span>
                                <InputText type="password"
                                    placeholder="Confirme a senha"
                                    size="100"
                                    name="confirmaSenha"
                                    value={this.state.confirmaSenha}
                                    onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="p-col-12">
                            <Button type="button" label="Alterar" disabled={statusBotao} className="p-button-raised p-button-rounded" icon="pi pi-save" iconPos="left" onClick={this.salvar} />
                        </div>
                        <div className="p-col-12">
                            <Link to={{ pathname: 'login' }}>
                                <Button type="button" icon="pi pi-arrow-left" iconPos="left" className="p-button-raised p-button-rounded p-button-secondary" label="Voltar" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}