import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Panel } from 'primereact/panel';
import React, { Component } from 'react';
import api from '../../service/api';
import { getRole, isAuthenticated } from '../../service/auth';
import { Role } from '../../service/role';
import Cookies from 'js-cookie'
import ReactGA from 'react-ga';
import { Event } from '../../service/analytics';

export class ShowVagaEmpresa extends Component {
    constructor() {
        super();
        this.state = {
            vaga: {},
            descricao: null,
            role: null,
            statusBtnApagar: false
        }
    }
    componentDidMount() {
        if (!isAuthenticated()) {
            window.location.href = `/#/login`;
        }
        const idVaga = Cookies.get("idVaga")
        this.getVagaById(idVaga);
        this.getRole();
        this.initializeReactGA();
    }

    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/showVagaEmpresa');
    }

    getRole = () => {
        var role = getRole();
        this.setState({ role: role[0] });
    }
    updateVaga = () => {
        Event("Empresas", "Editar vaga", "Botao editar acionado");
        window.location.href = "/#/updateVaga";
    }

    deleteVaga = (e) => {
        e.preventDefault();
        if (window.confirm("Deseja realmente apagar essa vaga?")) {
            Event("Empresas", "Deletar vaga", "Botao deletar acionado");
            this.setState({ statusBtnApagar: true });
            api.delete('/vagas/' + this.state.vaga.id)
                .then(res => {
                    this.growl.show({ severity: 'success', summary: 'Successo', detail: 'Vaga apagada com sucesso' });
                    setTimeout(() => {
                        window.location.href = "/#/vagaEmpresa";
                    }, 1000);
                })
                .catch(error => {
                    this.setState({ statusBtnSalvar: false });
                    this.growl.show({ severity: 'error', summary: 'Erro', detail: 'Não foi possível apagara a Vaga' });
                });
        }

    }

    getVagaById = async (id) => {
        const response = await api.get('/vagas/' + id);
        const vag = response.data;
        this.setState({ vaga: vag });
        let newText = vag.descricao.split('\n').map((item, i) => <p key={i}>{item}</p>);
        this.setState({ descricao: newText });
    }
    voltar() {
        window.history.go(-1);
    }
    render() {
        const statusBotao = this.state.statusBtnSalvar;
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <h1>Exibir Vaga</h1>
                        <div className="p-grid p-fluid">
                            <Growl ref={(el) => this.growl = el} />
                            <div className="p-col-12 p-lg-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-12">
                                        <Panel header="Dados da Empresa">
                                            <div className="p-col-12 p-md-12">
                                                <label htmlFor="cb2"><b>Empresa:</b> {this.state.vaga.empresa}</label>
                                            </div>
                                            <div className="p-col-12 p-md-12">
                                                <label htmlFor="cb2"><b>Email:</b><a href={`mailto:${this.state.vaga.emailReponsavel}`}> {this.state.vaga.emailReponsavel} </a> </label>
                                            </div>
                                        </Panel>
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <Panel header="Detalhes da Vaga">

                                            <div className="p-col-12 p-md-12">
                                                <label htmlFor="cb2"><b>Título da Vaga:</b> {this.state.vaga.titulo}</label>
                                            </div>
                                            <div className="p-col-12 p-md-12">
                                                <label htmlFor="cb2"><b>Cargo:</b> {this.state.vaga.cargo}</label>
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <label htmlFor="cb2"><b>Tipo:</b> {this.state.vaga.tipo}</label>
                                            </div>
                                        </Panel>
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <Panel header="Localidade da Vaga">
                                            <div className="p-col-12 p-md-12">
                                                <label htmlFor="cb2"><b>Estado: </b> {this.state.vaga.estado}</label>
                                            </div>
                                            <div className="p-col-12 p-md-12">
                                                <label htmlFor="cb2"><b>Cidade: </b> {this.state.vaga.cidade}</label>
                                            </div>

                                        </Panel>
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <Panel header="Descrição da Vaga">
                                            {this.state.descricao}
                                        </Panel>
                                    </div>
                                </div>
                            </div>
                            {this.state.role === Role.Company &&
                                <div className="p-col-6 p-md-3" style={{ align: 'rigth' }}>
                                    <Button label="Editar" icon="pi pi-pencil" iconPos="left" className="p-button-raised p-button-rounded p-button-primary" style={{ marginBottom: '10px' }} onClick={this.updateVaga} />
                                </div>

                            }
                            {this.state.role === Role.Company &&
                                <div className="p-col-6 p-md-3" style={{ align: 'rigth' }}>
                                    <Button label="Apagar" icon="pi pi-trash" iconPos="left" disabled={statusBotao} className="p-button-raised p-button-rounded p-button-success" style={{ marginBottom: '10px' }} onClick={this.deleteVaga} />
                                </div>

                            }

                            <div className="p-col-6 p-md-3" style={{ align: 'rigth' }}>
                                <Button label="Voltar" icon="pi pi-arrow-left" iconPos="left" className="p-button-raised p-button-rounded p-button-danger" style={{ marginBottom: '10px' }} onClick={this.voltar} />
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        );
    }
}