import React from 'react'
import Parallax from './index'
import { isAuthenticated, getRole } from '../../service/auth';
import ReactGA from 'react-ga';
import { Role } from '../../service/role';

export class Home extends React.Component {
    login = () => {
        window.location.href = "#registrar";
    }
    isDesktop() {
        return window.innerWidth > 1024;
    }
    componentDidMount() {
        var role = getRole();
        if (isAuthenticated()) {
            let pagina = 'dashboard';
            if (role) {
                if (role[0] === Role.Company) {
                    pagina = 'dashboardEmpresa';
                }
                if (role[1] === Role.Admin) {
                    pagina = 'dashboardAdmin';
                }
            }
            window.location.href = `/#/${pagina}`;
        }
        this.initializeReactGA();
    }

    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/');
    }

    render() {
        const desktop = this.isDesktop();
        let home = "assets/layout/images/home.jpg";
        let dev = "assets/layout/images/dev_12.jpg";
        let rh = "assets/layout/images/note.jpg";
        let textoHome = this.isDesktop() ? "Somos uma plataforma totalmente gratuíta voltada para profissionais de tecnologia e recrutadores. Fique na mira das maiores empresas ou contrate aquele perfil tão desejado. #vamosnessa" : "Plataforma gratuíta para profissionais de tecnologia e recrutadores.";
        let textoDev = "Nesse momento uma grande empresa busca por alguém com o seu perfil. Faça já o seu cadastro e encontre as melhores oportunidades naquela tão sonhada empresa, pode ter uma vaga do lado da sua casa. Não vai ficar ai parado? #partiu";
        let textoRec = "Gerencie suas vagas de maneira inteligente, encontre os melhores talentos, divulgue suas vagas, contrate em apenas alguns cliques. Junte-se as melhores empresas. Crie já a sua conta. #euquero";
        const inlineStyleHome = {
            background: '#015249',
            left: '50%',
            top: '50%',
            color: '#fff',
            position: 'absolute',
            borderRadius: '10px',
            letterSpacing: '2px',
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingLeft: '16px',
            paddingRight: '16px',
            cursor: 'pointer',
            transform: 'translate(-50%, -50%)',
        }
        const inlineStyleRec = {
            background: '#262228',
            left: '50%',
            top: '50%',
            color: '#fff',
            position: 'absolute',
            borderRadius: '10px',
            letterSpacing: '2px',
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingLeft: '16px',
            paddingRight: '16px',
            cursor: 'pointer',
            transform: 'translate(-50%, -50%)',
        }
        const inlineStyleDev = {
            background: '#233237',
            left: '50%',
            top: '50%',
            color: '#FFF',
            position: 'absolute',
            borderRadius: '10px',
            letterSpacing: '2px',
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingLeft: '16px',
            paddingRight: '16px',
            cursor: 'pointer',
            transform: 'translate(-50%, -50%)',
        }
        return (
            <Parallax ref={ref => (this.parallax = ref)} pages={3}>
                <Parallax.Layer offset={0} speed={1} style={{ backgroundColor: '#000' }} />
                <Parallax.Layer offset={1} speed={1} style={{ backgroundColor: '#000' }} />
                <Parallax.Layer offset={2} speed={1} style={{ backgroundColor: '#000' }} />

                <Parallax.Layer
                    offset={0}
                    speed={0.0}
                    onClick={() => this.parallax.scrollTo(1)}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="bg-dim full-bg-size" style={{
                        backgroundImage: `url(${home})`, display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <div style={inlineStyleHome}>
                            {
                                desktop ? <strong>Thebestdevs</strong> : <center><strong>Thebestdevs</strong></center>
                            }

                            <br /><br />
                            {
                                desktop ? textoHome : <center>{textoHome}</center>
                            }

                            <br /><br /><center><strong>Avançar</strong></center>
                        </div>
                    </div>
                </Parallax.Layer>
                <Parallax.Layer
                    offset={1}
                    speed={0.0}
                    onClick={() => this.parallax.scrollTo(2)}
                    style={{ backgroundImage: `url(${dev})`, backgroundSize: 'cover' }}>
                    <div style={inlineStyleDev}>
                        <strong>Profissional de TI</strong>
                        <br /><br />
                        {textoDev}
                        <br /><br /><center><strong>Avançar</strong></center>
                    </div>
                </Parallax.Layer>
                <Parallax.Layer
                    offset={2}
                    speed={0.0}
                    onClick={this.login}
                    style={{ backgroundImage: `url(${rh})`, backgroundSize: 'cover' }}>
                    <div style={inlineStyleRec}>
                        <strong>Recrutador(a)</strong>
                        <br /><br />
                        {textoRec}
                        <br /><br /><center><strong>Avançar</strong></center>
                    </div>
                </Parallax.Layer>
            </Parallax>
        )
    }
}

