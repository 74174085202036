import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react';
import api from '../../service/api';
import { getUser, isAuthenticated } from '../../service/auth';
import ReactGA from 'react-ga';
import { Event } from '../../service/analytics';

export class Perfil extends Component {
    constructor() {
        super();
        this.state = {
            id: null,
            email: null,
            senha: null
        }
    }
    componentDidMount() {
        if (!isAuthenticated()) {
            window.location.href = `/#/login`;
        }
        this.getPerfilByEmail();
    }
    
    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/perfil');
    }

    getPerfilByEmail = async () => {
        const user = JSON.parse(getUser());
        const email = user ? user.sub : "";
        try {
            const response = await api.get(`/usuarios/find/${email}`);
            if (response.status === 200) {
                this.setState({ id: response.data.id });
                this.setState({ nome: response.data.nome });
                this.setState({ email: response.data.email });
            }
        } catch (err) {
            console.error(err);
        }
    }

    handleChange = (event) => {
        const { target: { name, value } } = event
        this.setState({ [name]: value })
    }

    salvar = (e) => {
        e.preventDefault();
        Event("Geral","Atualizar Perfil","Botao salvar acionado");
        if (this.validaForm()) {
            this.setState({ statusBtnSalvar: true });
            let usuario = {
                id: this.state.id,
                nome: this.state.nome,
                email: this.state.email,
                senha: this.state.senha,
                confirmaSenha: null
            };
            this.alterar(usuario);
        }
    }

    alterar = (usuario) => {
        api.put(`/usuarios/${usuario.id}`, usuario)
            .then(res => {
                if (res.status === 200) {
                    this.growl.show({ severity: 'success', summary: 'Successo', detail: 'Usuário alterado com sucesso' });
                    this.setState({ statusBtnSalvar: false });
                }
            })
            .catch(error => {
                this.setState({ statusBtnSalvar: true });
                this.growl.show({ severity: 'error', summary: 'Erro', detail: 'Não foi possível alterar o Usúario' });
            });
    }

    validaForm = () => {
        let isValid = true;
        let erros = [];
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
        let tamanhoNome = this.state.nome ? this.state.nome.length : 0;
        if (tamanhoNome < 5) {
            erros.push({ severity: 'error', summary: 'Nome inválido', detail: 'Nome completo é obrigatório' });
            isValid = false;
        }
        if (!strongRegex.test(this.state.senha)) {
            erros.push({ severity: 'error', summary: 'Senha inválida', detail: 'Senha deve conter no mínimo 1 caracter especial, 1 letra minuscula, 1 letra maiuscula e ter 8 caracteres' });
            isValid = false;
        }
        if (this.state.confirmaSenha !== this.state.senha) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'As senhas não conferem' });
            isValid = false;
        }
        if (erros.length) {
            this.growl.show(erros);
        }
        return isValid;
    }
    render() {
        const statusBotao = this.state.statusBtnSalvar;
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <h1>Dados do Usuário</h1>
                        <div className="p-grid p-fluid">

                            <div className="p-col-12 p-lg-12">
                                <Growl ref={(el) => this.growl = el} />
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon"><i className="pi pi-envelope" /></span>
                                            <InputText
                                                placeholder="Email"
                                                disabled
                                                name="email"
                                                maxLength="30"
                                                value={this.state.email}
                                                onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon"><i className="pi pi-user" /></span>
                                            <InputText
                                                placeholder="Nome Completo"
                                                name="nome"
                                                maxLength="30"
                                                value={this.state.nome}
                                                onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon"><i className="pi pi-key" /></span>
                                            <InputText
                                                type="password"
                                                placeholder="Senha"
                                                name="senha"
                                                value={this.state.senha}
                                                onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon"><i className="pi pi-key" /></span>
                                            <InputText
                                                type="password"
                                                placeholder="Confirme a Senha"
                                                name="confirmaSenha"
                                                value={this.state.confirmaSenha}
                                                onChange={this.handleChange} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="p-col-6 p-md-3" style={{ align: 'right' }}>
                                <Button label="Salvar" icon="pi pi-save" iconPos="left" disabled={statusBotao} className="p-button-raised p-button-rounded p-button-primary" style={{ marginBottom: '10px' }} onClick={this.salvar} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}