import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import React, { Component } from 'react';
import api from '../../service/api';
import { isAuthenticated, getRole } from '../../service/auth';
import { Role } from '../../service/role';
import ReactGA from 'react-ga';
import { Event } from '../../service/analytics';

export class Ranking extends Component {

    constructor() {
        super();
        this.state = {
            rankings: [],
            layout: 'list',
            desenvolvedor: {},
            tecnologiasDev: [],
            display: true,
            visible: false,
            loading: false,
            sortOptions: [
                { label: 'Profissional', value: 'profissional' },
                { label: 'Email', value: 'email' }
            ],
        };
        this.showDev = this.showDev.bind(this);
    }

    componentDidMount() {
        var role = getRole();
        if (!isAuthenticated() || (role[0] === Role.User && role[1] !== Role.Admin)) {
            window.location.href = `/#/login`;
        }
        this.ranking();
        setTimeout(() => {
            this.setState({ display: false });
        }, 5000);
        this.initializeReactGA();
    }

    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/ranking');
    }

    ordenarPorPontuacao(a, b) {
        return b.pontuacao - a.pontuacao;
    }

    ranking = async () => {
        try {
            this.setState({ loading: true });
            const response = await api.get('/ranking');
            let rankingsArray = response.data;
            rankingsArray.sort(this.ordenarPorPontuacao);
            this.setState({ rankings: rankingsArray });
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({ loading: false });
        }
    };

    getDevById = async (id) => {
        const response = await api.get(`/desenvolvedores/${id}`);
        const dev = response.data;
        this.setState({ desenvolvedor: dev });
        if (dev.tecnologias) {
            this.setState({ tecnologiasDev: dev.tecnologias });
        }
    }

    showDev = (e) => {
        Event("Empresas", "Exibindo profissional", "Link exibir acionado");
        const idDev = e.data.idDesenvolvedor;
        this.getDevById(idDev);
        this.setState({ visible: true });
    }

    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-search" className="p-button-raised p-button-rounded p-button-success"></Button>
        </div>;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    voltar() {
        window.history.go(-1);
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-times" className="p-button-raised p-button-rounded p-button-danger" onClick={() => this.setState({ display: false })} label="Fechar" />
            </div>
        );
        const dialogFooterDev = (
            <div>
                <Button icon="pi pi-times" className="p-button-raised p-button-rounded p-button-danger" onClick={() => this.setState({ visible: false })} label="Fechar" />
            </div>
        );
        var rankinngCount = this.state.rankings ? this.state.rankings.length : 0;
        var footer = "Total de  " + rankinngCount + ' rankeados';
        const { loading } = this.state;
        let tamanhoModalPontos = this.isDesktop() ? '40vw' : '90vw';

        return (
            <div>
                <div className="p-grid">
                    <div className="p-col-12 p-md-12">
                        {loading && <img src="assets/layout/images/preloader.gif" width="35" alt="Carregando" />}
                        <div className="card">
                            <h1>Ranking de Profissionais </h1>
                            <div className="p-col-12">
                                <DataTable value={this.state.rankings} onRowClick={this.showDev} selectionMode="single" paginator={true} rows={10}
                                    responsive={true} footer={footer}>
                                    <Column field="desenvolvedor" header="Profissional" sortable={true} />
                                    <Column field="email" header="Email" sortable={true} />
                                    <Column field="pontuacao" header="Pontuação" sortable={true} style={{ width: '15%' }} />
                                    <Column body={this.actionTemplate} style={{ textAlign: 'center', width: '6em' }} />
                                </DataTable>
                            </div>
                            <Dialog header="Critérios" visible={this.state.display} modal={true} footer={dialogFooter} onHide={() => this.setState({ display: false })} style={{ width: `${tamanhoModalPontos}` }}>
                                <p><b>1 -</b> Tecnologias 10 pontos <b>[Toda tecnologia informada]</b></p>
                                <p><b>2 -</b> Github 30 pontos <b>[Perfil no Github]</b></p>
                                <p><b>3 -</b> Containers  50 pontos <b>[Docker, Kubernets]</b></p>
                                <p><b>4 -</b> Clouds 60 pontos <b>[AWS, Heroku, Google Cloud, Azure]</b></p>
                            </Dialog>

                            <Dialog header="Exibir Profissional" footer={dialogFooterDev} visible={this.state.visible} style={{ width: this.isDesktop() ? '60vw' : '100vw' }} modal={true} onHide={() => this.setState({ visible: false })}>
                                <div className="p-col-12 p-lg-12">
                                    <div className="p-col-12 p-md-12">
                                        <Panel header="Dados Pessoais">
                                            <div className="p-col-12 p-md-12">
                                                <label htmlFor="cb2"><b>Nome:</b> {this.state.desenvolvedor.nome}</label>
                                            </div>
                                            <div className="p-col-12 p-md-12">
                                                <label htmlFor="cb2"><b>Email:</b><a href={`mailto:${this.state.desenvolvedor.email}`}> {this.state.desenvolvedor.email} </a> </label>
                                            </div>
                                            <div className="p-col-12 p-md-4">
                                                <label htmlFor="cb2"><b>Sexo:</b> {this.state.desenvolvedor.sexo === 'M' ? 'Masculino' : 'Feminino'}</label>
                                            </div>
                                        </Panel>
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <Panel header="Endereço">
                                            <div className="p-col-12 p-md-2">
                                                <label htmlFor="cb2"><b>Estado: </b> {this.state.desenvolvedor.estado}</label>
                                            </div>
                                            <div className="p-col-12 p-md-12">
                                                <label htmlFor="cb2"><b>Cidade: </b> {this.state.desenvolvedor.cidade}</label>
                                            </div>
                                        </Panel>
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <Panel header="Redes Sociais">
                                            <div className="p-col-12 p-md-12">
                                                <label htmlFor="cb2"><b>Github: </b> {this.state.desenvolvedor.gitHub}</label>
                                            </div>
                                            <div className="p-col-12 p-md-12">
                                                <label htmlFor="cb2"><b>Linkedin: </b> {this.state.desenvolvedor.linkedin}</label>
                                            </div>
                                        </Panel>
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <Panel header="Tecnologias do Profissional">
                                            {
                                                this.state.tecnologiasDev && this.state.tecnologiasDev.map((tecnologia, index) => {
                                                    return (
                                                        <div className="p-col-12 p-lg-6">
                                                            <div className="p-grid" key={index}>
                                                                <div className="p-col-12 p-md-6">
                                                                    <label htmlFor="cb2" className="p-checkbox-label">{tecnologia}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </Panel>
                                    </div>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}