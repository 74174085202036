import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { logout } from './service/auth';
import { isAuthenticated } from './service/auth';


export class AppTopbar extends Component {

    static defaultProps = {
        onToggleMenu: null
    }

    static propTypes = {
        onToggleMenu: PropTypes.func.isRequired
    }

    logout = () => {
        logout();
    }
    isLogin() {
        return !isAuthenticated();
    }
    render() {
        return (
            <div className="layout-topbar clearfix">
                {!this.isLogin() ?
                    <button className="p-link layout-menu-button" onClick={this.props.onToggleMenu}>
                        <span className="pi pi-bars" />
                    </button>
                    :
                    <div style={{ align: 'center' }} >Seja bem vindo ao Thebestdevs</div>
                }
                {!this.isLogin() ?
                    <div className="layout-topbar-icons">
                        <button className="p-link" onClick={this.logout}>
                            <span className="layout-topbar-item-text">User</span>
                            <span className="layout-topbar-icon pi pi-fw pi-power-off" />
                        </button>
                    </div>
                    : ""
                }
            </div>
        );
    }
}