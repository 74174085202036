import Cookies from 'js-cookie';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link, withRouter } from "react-router-dom";
import '../../App.scss';
import { Event } from '../../service/analytics';
import api from '../../service/api';
import { isAuthenticated } from '../../service/auth';

export class EsqueciSenha extends Component {

    constructor() {
        super();
        this.state = {
            email: null,
            error: null,
            statusBtnReenviar: false,
            loading: false
        };
    }
    componentDidMount() {
        if (isAuthenticated()) {
            window.location.href = `/#/dashboard`;
        }
        this.initializeReactGA();
    }
    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/esqueci-senha');
    }
    isDesktop() {
        return window.innerWidth > 1024;
    }
    reenviarSenha = async (e) => {
        e.preventDefault();
        Event("Geral","Reenviar Senha","Botao reenviar acionado");
        const { email } = this.state;
        if (this.validaForm()) {
            this.setState({ loading: true });
            this.setState({ statusBtnReenviar: true });
            Cookies.set("email", this.state.email);
            api.post("/resetSenha", { email })
                .then(res => {
                    if (res.status === 200) {
                        this.growl.show({ severity: 'success', summary: 'Successo', detail: 'Código enviado no seu email' });
                        setTimeout(() => {
                            this.setState({ loading: false });
                            window.location.href = `/#/login`;
                        }, 3000);
                    } else {
                        this.setState({ loading: false });
                        this.setState({ statusBtnReenviar: false });
                        this.growl.show({ severity: 'error', summary: 'Erro', detail: 'Não foi possível reenviar a senha, verifique o email' });
                    }
                })
                .catch(error => {
                    this.setState({ loading: false });
                    this.setState({ statusBtnReenviar: false });
                    Cookies.remove("email");
                    if (error.response) {
                        this.growl.show({ severity: 'error', summary: 'Erro', detail: error.response.data.message });
                    }
                });
        }
    };
    cancelar() {
        window.location.href = "/#/login";
    }
    validaForm = () => {
        let isValid = true;
        let erros = [];
        const emailRegex = new RegExp("^[a-z0-9][-_.+!#$%&'*/=?^`{|]{0,1}([a-z0-9][-_.+!#$%&*/=?^`{|]{0,1})*[a-z0-9]@[a-z0-9][-.]{0,1}([a-z][-.]{0,1})*[a-z0-9].[a-z0-9]{1,}([.-]{0,1}[a-z]){0,}[a-z0-9]{0,}$");
        if (!emailRegex.test(this.state.email)) {
            erros.push({ severity: 'error', summary: 'Email inválido', detail: 'Email inválido' });
            isValid = false;
        }
        if (erros.length) {
            this.growl.show(erros);
        }
        return isValid;
    }
    handleChange = (event) => {
        const { target: { name, value } } = event
        this.setState({ [name]: value })
    }
    render() {
        const { loading } = this.state;
        const statusBotao = this.state.statusBtnReenviar;
        let dev = "assets/layout/images/dev_7.jpg";
        let css = this.isDesktop() ? "p-col-6 p-md-6 p-lg-6 p-fluid content" : "p-col-10 p-md12 p-lg-10 p-fluid content";
        let isFirefox = typeof InstallTrigger !== 'undefined';
        let tamanhoColuna = 'p-col-12';
        if(isFirefox){
            tamanhoColuna = 'p-col-11';
        }
        return (
            <div className="bg-dim full-bg-size" style={{
                backgroundImage: `url(${dev})`, display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderradius: '10px'
            }}>

                    <div className={css}>
                        {loading && <img src="assets/layout/images/preloader.gif" width="35" alt="Carregando" />}
                        <div className="p-col-12">
                            <Growl ref={(el) => this.growl = el} />
                        </div>
                        <div className="esqueci-senha-form">
                                <h5>Esqueci a senha</h5>
                                <div className={tamanhoColuna}>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon"><i className="pi pi-envelope" /></span>
                                        <InputText type="text"
                                            placeholder="Email"
                                            name="email"
                                            size="100"
                                            value={this.state.email}
                                            onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div className="p-col-12">
                                    <Button type="button" label="Reenviar"  icon="pi pi-undo" iconPos="left" className="p-button-raised p-button-rounded" disabled={statusBotao} onClick={this.reenviarSenha} />
                                </div>
                                <div className="p-col-12">
                                    <Link to={{ pathname: 'login' }}>
                                        <Button type="button" icon="pi pi-arrow-left" iconPos="left" className="p-button-raised p-button-rounded p-button-secondary" label="Voltar" />
                                    </Link>
                                </div>
                            </div>
                    </div>
                </div>
        )
    }
}
export default withRouter(EsqueciSenha);