import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react';
import api from '../../service/api';
import { isAuthenticated,getRole,getUser } from '../../service/auth';
import { Role } from '../../service/role';
import ReactGA from 'react-ga';
import { Event } from '../../service/analytics';

export class Empresa extends Component {
    constructor() {
        super();
        this.state = {
            id: null,
            nome: null,
            nomeReponsavel: null,
            emailReponsavel: null,
            telefone: null,
            site: null,
            statusBtnSalvar: false
        }
    }

    componentDidMount() {
        var role = getRole();
        if (!isAuthenticated() || role[0] !== Role.Company) {
            window.location.href = `/#/login`;
        }
        this.getEmpresaByEmail();
        this.initializeReactGA();
    }

    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/empresa');
    }
    
    getEmpresaByEmail = async () => {
        const user = JSON.parse(getUser());
        const email = user ? user.sub : "";
        try {
            const response = await api.get(`/empresas/find/email/${email}`);
            this.setState({ id: response.data.id });
            this.setState({ nome: response.data.nome });
            this.setState({ nomeReponsavel: response.data.nomeReponsavel });
            this.setState({ emailReponsavel: response.data.emailReponsavel });
            this.setState({ telefone: response.data.telefone });
            this.setState({ site: response.data.site });

        } catch (err) {
            if (err.response) {
                this.setState({ emailReponsavel: email });
            }
        }
    }

    handleChange = (event) => {
        const { target: { name, value } } = event
        this.setState({ [name]: value })
    }

    salvar = (e) => {
        e.preventDefault();
        if (this.validaForm()) {
            this.setState({ statusBtnSalvar: true });
            let empresa = {
                id: this.state.id,
                nome: this.state.nome,
                nomeReponsavel: this.state.nomeReponsavel,
                emailReponsavel: this.state.emailReponsavel,
                telefone: this.state.telefone,
                site: this.state.site
            };

            if (empresa.id) {
                this.alterar(empresa);
            } else {
                this.incluir(empresa);
            }
        }
    }
    
    incluir = (empresa) => {
        Event("Empresas","Incluindo Empresas","Botao Salvar acionado");
        api.post('/empresas', empresa)
            .then(res => {
                if (res.status === 201) {
                    this.setState({ id: res.data.id });
                    this.growl.show({ severity: 'success', summary: 'Successo', detail: 'Empresa incluida com sucesso' });
                    setTimeout(() => {
                        window.location.href = `/#/vaga`;
                    }, 2000);
                    this.setState({ statusBtnSalvar: false });
                }
            })
            .catch(error => {
                this.setState({ statusBtnSalvar: false });
                if(error.response){
                    this.growl.show({ severity: 'error', summary: 'Erro', detail: error.response.data.message });
                }
            });
    }
    
    alterar = (empresa) => {
        Event("Empresas","Alterando Empresa","Botao Salvar acionado");
        api.put(`/empresas/${empresa.id}`, empresa)
            .then(res => {
                if (res.status === 200) {
                    this.growl.show({ severity: 'success', summary: 'Successo', detail: 'Empresa alterada com sucesso' });
                }
                this.setState({ statusBtnSalvar: false });
            })
            .catch(error => {
                this.setState({ statusBtnSalvar: false });
                this.growl.show({ severity: 'error', summary: 'Erro', detail: 'Não foi possível alterar o Empresa' });
            });
    }

    validaForm = () => {
        let isValid = true;
        let erros = [];
        const tamanhoEmpresa = this.state.nome ? this.state.nome.length : 0;
        const emailRegex = new RegExp("^[a-z0-9][-_.+!#$%&'*/=?^`{|]{0,1}([a-z0-9][-_.+!#$%&*/=?^`{|]{0,1})*[a-z0-9]@[a-z0-9][-.]{0,1}([a-z][-.]{0,1})*[a-z0-9].[a-z0-9]{1,}([.-]{0,1}[a-z]){0,}[a-z0-9]{0,}$");
        if (tamanhoEmpresa < 2) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'Nome da empresa deve conter no minimo 2 caracteres' });
            isValid = false;
        }
        let tamanhoNome =  this.state.nomeReponsavel ? this.state.nomeReponsavel.length : 0;  
        if (tamanhoNome < 5) {
            erros.push({ severity: 'error', summary: 'Nome Responsável inválido', detail: 'Nome completo é obrigatório' });
            isValid = false;
        }
        if(!emailRegex.test(this.state.emailReponsavel)) {
            erros.push({ severity: 'error', summary: 'Email inválido', detail: 'Email inválido' });
            isValid = false;
        }
        if (erros.length) {
            this.growl.show(erros);
        }
        return isValid;
    }
    render() {
        const statusBotao = this.state.statusBtnSalvar;
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <h1>Dados da Empresa</h1>
                        <div className="p-grid p-fluid">
                            <Growl ref={(el) => this.growl = el} />
                            <div className="p-col-12 p-lg-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6">
                                        <InputText
                                            placeholder="Nome da Empresa"
                                            name="nome"
                                            value={this.state.nome}
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <InputText
                                            placeholder="Nome do Responsável"
                                            name="nomeReponsavel"
                                            value={this.state.nomeReponsavel}
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <InputText
                                            placeholder="Email Responsável"
                                            name="emailReponsavel"
                                            disabled
                                            value={this.state.emailReponsavel}
                                            onChange={this.handleChange} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <InputMask
                                            placeholder="Telefone Empresa"
                                            name="telefone"
                                            mask="(99) 9999-9999"
                                            value={this.state.telefone}
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <InputText
                                            placeholder="Site"
                                            name="site"
                                            value={this.state.site}
                                            onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-6 p-md-3" style={{ align: 'left' }}>
                                <Button label="Salvar" icon="pi pi-save" className="p-button-raised p-button-rounded" iconPos="left" disabled={statusBotao} style={{ marginBottom: '10px' }} onClick={this.salvar} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}