import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';
import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import api from '../../service/api';
import { isAuthenticated, getRole, getUser } from '../../service/auth';
import { Role } from '../../service/role';
import Cookies from 'js-cookie'
import ReactGA from 'react-ga';
import { Event } from '../../service/analytics';

export class VagasEmpresa extends Component {

    constructor() {
        super();
        this.state = {
            vagas: [],
            vaga: {},
            empresa: null,
            visible: false,
            descricaoVaga: null
        }
    }

    componentWillUnmount(){
        Cookies.remove("emailEmpresa");
    }

    componentDidMount() {
        if (!isAuthenticated()) {
            window.location.href = `/#/login`;
        }
        const empresa = this.props.match.params.empresa;
        var role = getRole();
        if(empresa && role[0] !== Role.Company){
            this.vagasEmpresa(empresa);
        }else{
            this.getEmpresaByEmail();
        }
        this.initializeReactGA();
    }

    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/vagaEmpresa');
    }

    getEmpresaByEmail = () => {
        const user = JSON.parse(getUser());
        var email = user ? user.sub : "";
        var role = getRole();
        var emailLocal = Cookies.get("emailEmpresa");
        if (emailLocal && (role[1] !== Role.Company)) {
            email = emailLocal;
        }

        this.setState({ loading: true });
        api.get(`/empresas/find/email/${email}`)
            .then(res => {
                if (res.status === 200) {
                    this.vagasEmpresa(res.data.nome);
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(error => {
                this.setState({ loading: false });
                this.growl.show({ severity: 'error', summary: 'Erro', detail: 'Não foi possível obter as vagas da empresa' });
            });
    }

    vagasEmpresa = async (empresa) => {
        try {
            this.setState({ loading: true });
            const response = await api.get(`/vagas/search/${empresa}`);
            this.setState({ vagas: response.data });
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({ loading: false });
        }
    }

    getVagaById = async (id) => {
        Event("Geral", "Ver vaga", "Link ver vaga acionado");
        const response = await api.get('/vagas/' + id);
        const vag = response.data;
        this.setState({ vaga: vag });
        let newText = vag.descricao.split('\n').map((item, i) => <p key={i}>{item}</p>);
        this.setState({ descricaoVaga: newText });
    }

    showVaga = (e) => {
        var role = getRole();
        Event("Desenvolvedores", "Exibir vaga", "Link vaga acionado");
        const idVaga = e.data.id;
        if (role[0] === Role.User) {
            this.getVagaById(idVaga);
            this.setState({ visible: true });
        } else {
            Cookies.set("idVaga", idVaga);
            window.location.href = `/#/showVagaEmpresa`
        }
    }

    handleChange = (event) => {
        const { target: { name, value } } = event
        this.setState({ [name]: value })
    }
    voltar() {
        window.history.go(-1);
    }

    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-search" className="p-button-raised p-button-rounded p-button-success"></Button>
        </div>;
    }
    
    isDesktop() {
        return window.innerWidth > 1024;
    }

    render() {
        var role = getRole();
        let titulo = "Vagas Empresa";
        if(role[0] === Role.Company){
            titulo = "Suas Vagas";
        }
        const dialogFooter = (
            <div>
                <Button icon="pi pi-times" className="p-button-raised p-button-rounded p-button-danger" onClick={() => this.setState({ visible: false })} label="Fechar" />
            </div>
        );

        var vagasCount = this.state.vagas ? this.state.vagas.length : 0;
        var footer = "Total de  " + vagasCount + ' vagas';
        const { loading } = this.state;
        return (
            <div className="p-grid">
                {loading && <img src="assets/layout/images/preloader.gif" width="35" alt="Carregando" />}
                <div className="p-col-12">
                    <div className="card">
                        <h1>{titulo}</h1>
                        <div className="p-grid p-fluid">
                            <Growl ref={(el) => this.growl = el} />
                            <div className="p-col-12 p-lg-12">
                                <DataTable value={this.state.vagas} emptyMessage="Nenhum registro encontrado" style={{ marginBottom: '20px' }} responsive={true}
                                    selectionMode="single" onRowClick={this.showVaga} paginator={true} rows={10} footer={footer} >
                                    <Column field="titulo" header="Vaga" sortable={true} />
                                    <Column field="empresa" header="Empresa" sortable={true} />
                                    <Column field="tipo" header="Tipo" sortable={true} style={{ width: '12%' }} />
                                    <Column field="dataCriacao" header="Data" sortable={true} style={{ width: '10%' }} />
                                    <Column body={this.actionTemplate} style={{ textAlign: 'center', width: '4em' }} />
                                </DataTable>
                            </div>
                            <div className="p-col-6 p-md-3" style={{ align: 'rigth' }}>
                                <Button label="Voltar" icon="pi pi-arrow-left" iconPos="left" className="p-button-raised p-button-rounded p-button-danger" style={{ marginBottom: '10px' }} onClick={this.voltar} />
                            </div>
                            <Dialog header="Exibir Vaga" footer={dialogFooter} visible={this.state.visible} style={{ width: this.isDesktop() ? '60vw' : '100vw' }} modal={true} onHide={() => this.setState({ visible: false })}>
                                <div className="p-col-12 p-lg-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-12">
                                            <Panel header="Dados da Empresa">
                                                <div className="p-col-12 p-md-12">
                                                    <label htmlFor="cb2"><b>Empresa:</b> {this.state.vaga.empresa}</label>
                                                </div>
                                                <div className="p-col-12 p-md-12">
                                                    <label htmlFor="cb2"><b>Email:</b><a href={`mailto:${this.state.vaga.emailReponsavel}`}> {this.state.vaga.emailReponsavel} </a> </label>
                                                </div>
                                            </Panel>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Panel header="Detalhes da Vaga">

                                                <div className="p-col-12 p-md-12">
                                                    <label htmlFor="cb2"><b>Título da Vaga:</b> {this.state.vaga.titulo}</label>
                                                </div>
                                                <div className="p-col-12 p-md-12">
                                                    <label htmlFor="cb2"><b>Cargo:</b> {this.state.vaga.cargo}</label>
                                                </div>
                                                <div className="p-col-12 p-md-6">
                                                    <label htmlFor="cb2"><b>Tipo:</b> {this.state.vaga.tipo}</label>
                                                </div>
                                            </Panel>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Panel header="Localidade da Vaga">
                                                <div className="p-col-12 p-md-12">
                                                    <label htmlFor="cb2"><b>Estado: </b> {this.state.vaga.estado}</label>
                                                </div>
                                                <div className="p-col-12 p-md-12">
                                                    <label htmlFor="cb2"><b>Cidade: </b> {this.state.vaga.cidade}</label>
                                                </div>

                                            </Panel>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Panel header="Descrição da Vaga">
                                                {this.state.descricaoVaga}
                                            </Panel>
                                        </div>
                                    </div>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}