import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Growl } from 'primereact/growl';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react';
import api from '../../service/api';
import {isAuthenticated}  from '../../service/auth';
import ReactGA from 'react-ga';
import { Event } from '../../service/analytics';

export class Indicacao extends Component {
    constructor() {
        super();
        this.state = {
            nome: null,
            email: null,
            telefone: null,
            sexo: null,
            avaliacao: null,
            statusBtnindicar: false,
            sexos: [
                { label: 'Informe o Sexo', value: null },
                { label: 'Masculino', value: 'M' },
                { label: 'Feminino', value: 'F' }
            ]
        }
    }
    componentDidMount() {
        if(!isAuthenticated()){
            window.location.href = `/#/login`;
            window.location.reload();
        }
        this.initializeReactGA();
    }
    
    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/indicacao');
    }    

    voltar = (e) =>{
        e.preventDefault();
        window.history.go(-1);
    }

    handleChange = (event) => {
        const { target: { name, value } } = event
        this.setState({ [name]: value })
    }

    salvar = (e) => {
        e.preventDefault();
        Event("Desenvolvedores","Indicação","Botao salvar acionado");
        if(this.validaForm()){
            this.setState({ statusBtnindicar: true });
            let indicacao = {
                nome: this.state.nome,
                email: this.state.email,
                telefone: this.state.telefone,
                sexo: this.state.sexo
            };
            api.post('indicacoes',indicacao)
                .then(res => {
                    if(res.status === 201){
                        this.growl.show({ severity: 'success', summary: 'Successo', detail: 'Indicação salva com sucesso' });
                        this.setState({ statusBtnindicar: false });
                        setTimeout(() => {
                            window.history.go(-1);
                        }, 2000);
                    }
                })
                .catch(err => {
                    this.setState({ statusBtnindicar: false });
                    if (err.response) {
                        this.growl.show({ severity: 'error', summary: 'Resultado', detail: err.response.data.message });
                    }
                });
        }
        
    }
    validaForm = () => {
        let isValid = true;
        let erros = [];
        const emailRegex = new RegExp("^[a-z0-9][-_.+!#$%&'*/=?^`{|]{0,1}([a-z0-9][-_.+!#$%&*/=?^`{|]{0,1})*[a-z0-9]@[a-z0-9][-.]{0,1}([a-z][-.]{0,1})*[a-z0-9].[a-z0-9]{1,}([.-]{0,1}[a-z]){0,}[a-z0-9]{0,}$");
        let tamanhoNome =  this.state.nome ? this.state.nome.length : 0;  
        if (tamanhoNome < 5) {
            erros.push({ severity: 'error', summary: 'Nome inválido', detail: 'Nome completo é obrigatório' });
            isValid = false;
        }
        if(!emailRegex.test(this.state.email)) {
            erros.push({ severity: 'error', summary: 'Email inválido', detail: 'Email inválido' });
            isValid = false;
        }
        if(!this.state.sexo){
            erros.push( {severity:'error', summary:'Campo sexo', detail:'Sexo é obrigatório'});
            isValid = false;
        }
        if(erros.length){
            this.growl.show(erros);    
        }
        return isValid;
    }
    render() {
        const statusBotao = this.state.statusBtnindicar;
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <h1>Nova Indicação</h1>
                        <div className="p-grid p-fluid">
                            <Growl ref={(el) => this.growl = el} />
                            <div className="p-col-12 p-lg-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6">
                                        <InputText
                                            placeholder="Nome Completo"
                                            maxLength="30"
                                            name="nome"
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <InputText
                                            placeholder="Email"
                                            name="email"
                                            maxLength="40"
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <Dropdown
                                            name="sexo"
                                            options={this.state.sexos}
                                            value={this.state.sexo}
                                            onChange={event => this.setState({ sexo: event.value })}
                                            autoWidth={false} />
                                    </div>

                                    <div className="p-col-12 p-md-6">
                                        <InputMask
                                            placeholder="Celular"
                                            name="telefone"
                                            mask="(99)9999-9999"
                                            value={this.state.telefone}
                                            onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-6 p-md-3" style={{ align: 'right' }}>
                                <Button label="Salvar" icon="pi pi-save" iconPos="left" className="p-button-raised p-button-rounded p-button-primary" disabled={statusBotao} style={{ marginBottom: '10px' }} onClick={this.salvar} />
                            </div>
                            <div className="p-col-6 p-md-3" style={{ align: 'right' }}>
                                <Button label="Voltar" icon="pi pi-arrow-left" iconPos="left" className="p-button-raised p-button-rounded p-button-danger" style={{ marginBottom: '10px' }} onClick={this.voltar} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}