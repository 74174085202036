import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Panel } from 'primereact/panel';
import React, { Component } from 'react';
import api from '../../service/api';
import {isAuthenticated}  from '../../service/auth';
import ReactGA from 'react-ga';

export class ShowDesenvolvedor extends Component {
    constructor() {
        super();
        this.state = {
            desenvolvedor: {},
            tecnologias: [],
        }
    }
    componentDidMount() {
        if(!isAuthenticated()){
            window.location.href = `/#/login`;
        }
        this.getDevById(this.props.match.params.idDesenvolvedor);
        this.initializeReactGA();
    }
    
    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/showDesenvolvedor');
    }

    getDevById = async (id) => {
        const response = await api.get(`/desenvolvedores/${id}`);
        const dev = response.data;
        this.setState({ desenvolvedor: dev });
        if(dev.tecnologias){
            this.setState({ tecnologias: dev.tecnologias });
        }
    };
    voltar() {
        window.history.go(-1);
    }
    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <h1>Exibir Profissional</h1>
                        <div className="p-grid p-fluid">
                            <Growl ref={(el) => this.growl = el} />
                            <div className="p-col-12 p-lg-12">
                                <div className="p-col-12 p-md-12">
                                    <Panel header="Dados Pessoais">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="cb2"><b>Nome:</b> {this.state.desenvolvedor.nome}</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="cb2"><b>Email:</b><a href={`mailto:${this.state.desenvolvedor.email}`}> {this.state.desenvolvedor.email} </a> </label>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <label htmlFor="cb2"><b>Sexo:</b> {this.state.desenvolvedor.sexo === 'M' ? 'Masculino' : 'Feminino'}</label>
                                        </div>
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-md-12">
                                    <Panel header="Endereço">
                                        <div className="p-col-12 p-md-2">
                                            <label htmlFor="cb2"><b>Estado: </b> {this.state.desenvolvedor.estado}</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="cb2"><b>Cidade: </b> {this.state.desenvolvedor.cidade}</label>
                                        </div>
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-md-12">
                                    <Panel header="Redes Sociais">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="cb2"><b>Github: </b> {this.state.desenvolvedor.gitHub}</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="cb2"><b>Linkedin: </b> {this.state.desenvolvedor.linkedin}</label>
                                        </div>
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-md-12">
                                    <Panel header="Tecnologias do Profissional">
                                        {
                                            this.state.tecnologias && this.state.tecnologias.map((tecnologia, index) => {
                                                return (
                                                    <div className="p-col-12 p-lg-6">
                                                        <div className="p-grid" key={index}>
                                                            <div className="p-col-12 p-md-6">
                                                                <label htmlFor="cb2" className="p-checkbox-label">{tecnologia}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </Panel>
                                </div>
                            </div>
                            <div className="p-col-6 p-md-3" style={{ align: 'right' }}>
                                <Button label="Voltar" icon="pi pi-arrow-left" iconPos="left" className="p-button-raised p-button-rounded p-button-danger" style={{ marginBottom: '10px' }} onClick={this.voltar} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}