import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Growl } from 'primereact/growl';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react';
import api from '../../service/api';
import { isAuthenticated, getRole, getUser } from '../../service/auth';
import { Role } from '../../service/role';
import ReactGA from 'react-ga';
import { Event } from '../../service/analytics';
export class Desenvolvedor extends Component {
    constructor() {
        super();
        this.onTecChange = this.onTecChange.bind(this);
        this.state = {
            id: null,
            sexo: null,
            nome: null,
            email: null,
            telefone: null,
            dataNascimento: null,
            cep: null,
            endereco: null,
            cidade: null,
            gitHub: null,
            linkedin: null,
            estado: null,
            message: null,
            statusBtnSalvar: false,
            tecnologias: [],
            tecnologia: [],
            erros: [],
            sexos: [
                { label: 'Informe o Sexo', value: null },
                { label: 'Masculino', value: 'M' },
                { label: 'Feminino', value: 'F' }
            ],
            estados: [
                { label: 'Informe o Estado', value: null },
                { label: 'AC', value: 'AC' },
                { label: 'AL', value: 'AL' },
                { label: 'AP', value: 'AP' },
                { label: 'AM', value: 'AM' },
                { label: 'BA', value: 'BA' },
                { label: 'CE', value: 'CE' },
                { label: 'DF', value: 'DF' },
                { label: 'ES', value: 'ES' },
                { label: 'GO', value: 'GO' },
                { label: 'MA', value: 'MA' },
                { label: 'MT', value: 'MT' },
                { label: 'MS', value: 'MS' },
                { label: 'MG', value: 'MG' },
                { label: 'PA', value: 'PA' },
                { label: 'PB', value: 'PB' },
                { label: 'PR', value: 'PR' },
                { label: 'PE', value: 'PE' },
                { label: 'PI', value: 'PI' },
                { label: 'RJ', value: 'RJ' },
                { label: 'RN', value: 'RN' },
                { label: 'RS', value: 'RS' },
                { label: 'RO', value: 'RO' },
                { label: 'RR', value: 'RR' },
                { label: 'SC', value: 'SC' },
                { label: 'SE', value: 'SE' },
                { label: 'SP', value: 'SP' },
                { label: 'TO', value: 'TO' }
            ]

        }
    }
    componentDidMount() {
        var role = getRole();
        if (!isAuthenticated() || role[0] !== Role.User) {
            window.location.href = `/#/login`;
        }
        this.tecs();
        this.getDesenvolvedorByEmail();
        this.initializeReactGA();
    }

    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/desenvolvedor');
    }

    getDesenvolvedorByEmail = async () => {
        const user = JSON.parse(getUser());
        const email = user ? user.sub : "";
        const role = getRole();
        try {
            const response = await api.get(`/desenvolvedores/find/${email}`);
            this.setState({ id: response.data.id });
            this.setState({ nome: response.data.nome });
            this.setState({ email: response.data.email });
            this.setState({ sexo: response.data.sexo });
            this.setState({ telefone: response.data.telefone });
            this.setState({ cep: response.data.cep });
            this.setState({ endereco: response.data.endereco });
            this.setState({ cidade: response.data.cidade });
            this.setState({ gitHub: response.data.gitHub });
            this.setState({ linkedin: response.data.linkedin });
            this.setState({ estado: response.data.estado });
            this.setState({ tecnologia: response.data.tecnologias });

        } catch (err) {
            if (err.response) {
                if (role[1] !== Role.Admin) {
                    this.setState({ email: email });
                }
            }
        }
    }

    tecs = async () => {
        const response = await api.get('/tecnologias');
        this.setState({ tecnologias: response.data });
    };

    handleChange = (event) => {
        const { target: { name, value } } = event
        this.setState({ [name]: value })
    }

    onTecChange(e) {
        let selectedTecs = [...this.state.tecnologia];
        if (e.checked) selectedTecs.push(e.value);
        else selectedTecs.splice(selectedTecs.indexOf(e.value), 1);
        this.setState({ tecnologia: selectedTecs });
    }

    salvar = (e) => {
        e.preventDefault();
        if (this.validaForm()) {
            this.setState({ statusBtnSalvar: true });
            let desenvolvedor = {
                id: this.state.id,
                nome: this.state.nome,
                email: this.state.email,
                telefone: this.state.telefone,
                cep: this.state.cep,
                endereco: this.state.endereco,
                estado: this.state.estado,
                cidade: this.state.cidade,
                gitHub: this.state.gitHub,
                sexo: this.state.sexo,
                linkedin: this.state.linkedin,
                tecnologias: this.state.tecnologia
            };
            if (desenvolvedor.id) {
                this.alterar(desenvolvedor)
            } else {
                this.incluir(desenvolvedor);
            }
        }
    }

    incluir = (desenvolvedor) => {
        Event("Desenvolvedores", "Incluir", "Botao incluir desenvolvedor acionado");
        api.post('/desenvolvedores', desenvolvedor)
            .then(res => {
                if (res.status === 201) {
                    this.setState({ statusBtnSalvar: false });
                    this.setState({ id: res.data.id });
                    this.growl.show({ severity: 'success', summary: 'Successo', detail: 'Profissional incluido com sucesso' });
                }
            })
            .catch(error => {
                this.setState({ statusBtnSalvar: false });
                this.growl.show({ severity: 'error', summary: 'Erro', detail: 'Não foi possível incluir o Profissional' });
            });
    }

    alterar = (desenvolvedor) => {
        Event("Desenvolvedores", "Alterar", "Botao alterar desenvolvedor acionado");
        api.put(`/desenvolvedores/${desenvolvedor.id}`, desenvolvedor)
            .then(res => {
                if (res.status === 200) {
                    this.growl.show({ severity: 'success', summary: 'Successo', detail: 'Profissional alterado com sucesso' });
                    this.setState({ statusBtnSalvar: false });
                }
            })
            .catch(error => {
                this.setState({ statusBtnSalvar: true });
                this.growl.show({ severity: 'error', summary: 'Erro', detail: 'Não foi possível alterar o Profissional' });
            });
    }

    limparEndereco = () => {
        this.setState({ endereco: '' });
        this.setState({ estado: null });
        this.setState({ cidade: '' });
    }

    buscaCep = (event) => {
        Event("Desenvolvedores", "Buscar cep", "Busca cep acionado");
        this.handleChange(event);
        let cep = this.state.cep ? this.state.cep.replace(/[^0-9.]/g, "") : "";

        if (cep.length === 8) {
            this.limparEndereco();
            api.get('cep/' + cep)
                .then(res => {
                    const enderecoRetorno = res.data;
                    this.setState({ endereco: enderecoRetorno.logradouro });
                    this.setState({ estado: enderecoRetorno.uf });
                    this.setState({ cidade: enderecoRetorno.localidade });
                })
                .catch(error => {
                    this.limparEndereco();
                });
        }
    }
    voltar = (e) =>{
        e.preventDefault();
        window.history.go(-1);
    }

    validaForm = () => {
        let isValid = true;
        let erros = [];
        const emailRegex = new RegExp("^[a-z0-9][-_.+!#$%&'*/=?^`{|]{0,1}([a-z0-9][-_.+!#$%&*/=?^`{|]{0,1})*[a-z0-9]@[a-z0-9][-.]{0,1}([a-z][-.]{0,1})*[a-z0-9].[a-z0-9]{1,}([.-]{0,1}[a-z]){0,}[a-z0-9]{0,}$");
        const tamanhoCidade = this.state.cidade ? this.state.cidade.length : 0;
        let tamanhoNome = this.state.nome ? this.state.nome.length : 0;
        if (tamanhoNome < 5) {
            erros.push({ severity: 'error', summary: 'Nome inválido', detail: 'Nome completo é obrigatório' });
            isValid = false;
        }
        if (!emailRegex.test(this.state.email)) {
            erros.push({ severity: 'error', summary: 'Email inválido', detail: 'Email inválido' });
            isValid = false;
        }
        if (!this.state.sexo) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'Sexo é obrigatório' });
            isValid = false;
        }
        if (!this.state.cep) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'CEP é obrigatório' });
            isValid = false;
        }
        if (!this.state.estado) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'Estado é obrigatório' });
            isValid = false;
        }
        if (tamanhoCidade < 3) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'Cidade inválida' });
            isValid = false;
        }
        if (this.state.tecnologia.length === 0) {
            erros.push({ severity: 'error', summary: 'Campo obrigatório', detail: 'Selecione ao menos uma tecnologia' });
            isValid = false;
        }
        if (erros.length) {
            this.growl.show(erros);
        }
        return isValid;
    }
    render() {
        const role = getRole();
        const statusBotao = this.state.statusBtnSalvar;
        var titulo = role[1] === Role.Admin ? "Novo Profissional" : "Meu perfil";
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <h1>{titulo}</h1>
                        <div className="p-grid p-fluid">

                            <div className="p-col-12 p-lg-12">
                                <Growl ref={(el) => this.growl = el} />
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6">
                                        <InputText
                                            placeholder="Nome Completo"
                                            name="nome"
                                            maxLength="30"
                                            value={this.state.nome}
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        {role[1] === Role.Admin ?
                                            <InputText
                                                placeholder="Email"
                                                name="email"
                                                maxLength="40"
                                                value={this.state.email}
                                                onChange={this.handleChange} />
                                            :
                                            <InputText
                                                placeholder="Email"
                                                name="email"
                                                disabled
                                                maxLength="40"
                                                value={this.state.email}
                                                onChange={this.handleChange} />
                                        }

                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <Dropdown
                                            name="sexo"
                                            options={this.state.sexos}
                                            value={this.state.sexo}
                                            onChange={event => this.setState({ sexo: event.value })}
                                            autoWidth={false} />
                                    </div>

                                    <div className="p-col-12 p-md-6">
                                        <InputMask
                                            placeholder="Celular"
                                            name="telefone"
                                            mask="(99) 99999-9999"
                                            value={this.state.telefone}
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <InputMask
                                            placeholder="CEP"
                                            name="cep"
                                            mask="99999-999"
                                            value={this.state.cep}
                                            onChange={this.buscaCep}
                                        />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText
                                            placeholder="Endereço"
                                            name="endereco"
                                            maxLength="60"
                                            value={this.state.endereco}
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <Dropdown
                                            name="estado"
                                            filter={true}
                                            options={this.state.estados}
                                            value={this.state.estado}
                                            onChange={event => this.setState({ estado: event.value })}
                                            autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <InputText
                                            placeholder="Cidade"
                                            name="cidade"
                                            maxLength="60"
                                            value={this.state.cidade}
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <InputText
                                            placeholder="Github"
                                            name="gitHub"
                                            value={this.state.gitHub}
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <InputText
                                            placeholder="Linkedin"
                                            name="linkedin"
                                            value={this.state.linkedin}
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <h2 style={{ fontSize: '16px' }}>Informe as tecnologias que domina</h2>
                                    </div>
                                    {
                                        this.state.tecnologias && this.state.tecnologias.map((tecnologia, index) => {
                                            const tecId = `tecnologia-${index}`;
                                            return (
                                                <div className="p-col-12 p-lg-2" key={tecnologia.nome}>
                                                    <div className="p-grid" key={index}>
                                                        <div className="p-col-12 p-md-12">
                                                            <Checkbox
                                                                inputId={tecId}
                                                                value={tecnologia.nome}
                                                                onChange={this.onTecChange}
                                                                checked={this.state.tecnologia.includes(tecnologia.nome)}
                                                            />
                                                            <label htmlFor="cb2" className="p-checkbox-label">{tecnologia.nome}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            <div className="p-col-6 p-md-3" style={{ align: 'right' }}>
                                <Button label="Salvar" disabled={statusBotao} icon="pi pi-save" iconPos="left" className="p-button-raised p-button-rounded p-button-primary" style={{ marginBottom: '10px' }} onClick={this.salvar} />
                            </div>
                            <div className="p-col-6 p-md-3" style={{ align: 'right' }}>
                                <Button label="Voltar" icon="pi pi-arrow-left" iconPos="left" className="p-button-raised p-button-rounded p-button-danger" style={{ marginBottom: '10px' }} onClick={this.voltar} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}