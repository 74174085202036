import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import classNames from 'classnames';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import React, { Component } from 'react';
import { Route } from "react-router-dom";
import './App.scss';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppProfile } from './AppProfile';
import { AppTopbar } from './AppTopbar';
import { BuscaProfissionais } from './components/buscaProfissionais/BuscaProfissionais';
import { BuscaVagas } from './components/buscaVagas/BuscaVagas';
import { Dashboard } from './components/dashboard/Dashboard';
import { DashboardAdmin } from './components/dashboardAdmin/DashboardAdmin';
import { DashboardEmpresa } from './components/dashboardEmpresa/DashboardEmpresa';
import { Desenvolvedor } from './components/desenvolvedores/Desenvolvedor';
import { Empresa } from './components/empresas/Empresa';
import { EsqueciSenha } from './components/esqueci-senha/EsqueciSenha';
import { Indicacao } from './components/indicacoes/Indicacao';
import { ListEmpresas } from './components/listEmpresas/ListEmpresas';
import { Login } from './components/login/Login';
import { Ranking } from './components/ranking/Ranking';
import { Registro } from './components/registro/Registro';
import { ShowDesenvolvedor } from './components/showDesenvolvedor/ShowDesenvolvedor';
import { ShowIndicacao } from './components/showIndicacao/ShowIndicacao';
import { ShowVagaEmpresa } from './components/showVagaEmpresa/ShowVagaEmpresa';
import { UpdateVaga } from './components/updateVaga/UpdateVaga';
import { Vaga } from './components/vagas/Vaga';
import { VagasEmpresa } from './components/vagasEmpresa/VagasEmpresa';
import { Usuarios } from './components/usuarios/Usuarios';
import { Usuario } from './components/usuario/Usuario';
import { Perfil } from './components/perfil/Perfil';
import { AlterarSenha } from './components/alterar-senha/AlterarSenha';
import { Home } from './components/home/Home';
import './layout/layout.scss';
import { getRole, isAuthenticated } from './service/auth';
import { Role } from './service/role';
class App extends Component {

    constructor() {
        super();
        if (!this.isLogin() || getRole() !== "") {
            this.onWrapperClick = this.onWrapperClick.bind(this);
            this.onToggleMenu = this.onToggleMenu.bind(this);
            this.onSidebarClick = this.onSidebarClick.bind(this);
            this.onMenuItemClick = this.onMenuItemClick.bind(this);
            this.createMenu();
        }
        this.state = {
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            login: !isAuthenticated()
        };
    }
    onWrapperClick(event) {
        if (!this.isLogin() || getRole() !== "") {
            if (!this.menuClick) {
                this.setState({
                    overlayMenuActive: false,
                    mobileMenuActive: false
                });
            }

            this.menuClick = false;
        }
    }

    onToggleMenu(event) {
        if (!this.isLogin() || getRole() !== "") {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.state.layoutMode === 'overlay') {
                    this.setState({
                        overlayMenuActive: !this.state.overlayMenuActive
                    });
                }
                else if (this.state.layoutMode === 'static') {
                    this.setState({
                        staticMenuInactive: !this.state.staticMenuInactive
                    });
                }
            }
            else {
                const mobileMenuActive = this.state.mobileMenuActive;
                this.setState({
                    mobileMenuActive: !mobileMenuActive
                });
            }

            event.preventDefault();
        }
    }

    onSidebarClick(event) {
        if (!this.isLogin() || getRole() !== "") {
            this.menuClick = true;
        }
    }

    onMenuItemClick(event) {
        if (!this.isLogin() || getRole() !== "") {
            if (!event.item.items) {
                this.setState({
                    overlayMenuActive: false,
                    mobileMenuActive: false
                })
            }
        }
    }

    createMenu() {
        var role = getRole();
        if (!this.isLogin() && role && role[0] !== "") {
            if (role && role[0] === Role.User) {
                this.menu = [
                    { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard' },
                    {
                        label: 'Profissional de TI', icon: 'pi pi-fw pi-users',
                        items: [
                            { label: 'Meu perfil', icon: 'pi pi-fw pi-user', to: '/desenvolvedor' },
                            { label: 'Nova Indicação', icon: 'pi pi-fw pi-share-alt', to: '/indicacao' },
                            { label: 'Busca de Vagas', icon: 'pi pi-fw pi-search', to: '/findVagas' },
                            { label: 'Empresas com Vagas', icon: 'pi pi-fw pi-list', to: '/empresas' },
                      
                        ]
                    },
                    { label: 'Conta', icon: 'pi pi-fw pi-user', to: '/perfil' },
                ];
            }
            if (role && role[0] === Role.Company) {
                this.menu = [
                    { label: 'Dashboard Empresa', icon: 'pi pi-fw pi-home', to: '/dashboardEmpresa' },
                    {
                        label: 'Empresas', icon: 'pi pi-fw pi-briefcase',
                        items: [
                            { label: 'Dados da Empresa', icon: 'pi pi-fw pi-briefcase', to: '/empresa' },
                            { label: 'Nova Vaga', icon: 'pi pi-fw pi-plus', to: '/vaga' },
                            { label: 'Suas Vagas', icon: 'pi pi-fw pi-list', to: '/vagaEmpresa' },
                            { label: 'Busca de Profissionais', icon: 'pi pi-fw pi-search', to: '/findProfissionais' },
                            { label: 'Ranking de Profissionais', icon: 'pi pi-fw pi-chart-bar', to: '/ranking' }
                        ]
                    },
                    { label: 'Conta', icon: 'pi pi-fw pi-user', to: '/perfil' },
                ];
            }
            if (role && role[1] === Role.Admin) {
                this.menu = [
                    { label: 'Dashboard Admin', icon: 'pi pi-fw pi-home', to: '/dashboardAdmin' },
                    {
                        label: 'Profissioanais de TI', icon: 'pi pi-fw pi-users',
                        items: [
                            { label: 'Novo Profissional', icon: 'pi pi-fw pi-user-plus', to: '/desenvolvedor' },
                            { label: 'Nova Indicação', icon: 'pi pi-fw pi-share-alt', to: '/indicacao' },
                            { label: 'Busca de Vagas', icon: 'pi pi-fw pi-search', to: '/findVagas' },
                        ]
                    },
                    {
                        label: 'Empresas', icon: 'pi pi-fw pi-briefcase',
                        items: [
                            { label: 'Empresas com Vagas', icon: 'pi pi-fw pi-list', to: '/empresas' },
                            { label: 'Busca de Profissionais', icon: 'pi pi-fw pi-search', to: '/findProfissionais' },
                            { label: 'Ranking de Profissionais', icon: 'pi pi-fw pi-chart-bar', to: '/ranking' }
                        ]
                    },
                    {
                        label: 'Usuários', icon: 'pi pi-fw pi-user',
                        items: [
                            { label: 'Todos Usuários', icon: 'pi pi-fw pi-list', to: '/usuarios' }
                        ]
                    },
                ];
            }
        }

    }

    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }
    
    isLogin() {
        return !isAuthenticated();
    }

    componentDidUpdate() {
        if (this.state.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    }

    render() {

        const wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive
        });

        const sidebarClassName = classNames("layout-sidebar", {
            'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
            'layout-sidebar-light': this.state.layoutColorMode === 'light'
        });

        return (
            <div className={wrapperClass} onClick={this.onWrapperClick}>
                {!this.isLogin() &&
                    <AppTopbar onToggleMenu={this.onToggleMenu} />
                }
                {!this.isLogin() &&
                    <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>
                        <AppProfile />
                        <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} />
                    </div>
                }
                <div>
                    <Route path="/" exact component={Home} />
                    <Route path="/login" exact component={Login} />
                    <Route path="/registrar" exact component={Registro} />
                    <Route path="/esqueci-senha" exact component={EsqueciSenha} />
                    <Route path="/alterar-senha" exact component={AlterarSenha} />
                </div>
                <div className="layout-main">
                    <Route path="/perfil" exact component={Perfil} />
                    <Route path="/dashboard" exact component={Dashboard} />
                    <Route path="/dashboardAdmin" exact component={DashboardAdmin} />
                    <Route path="/dashboardEmpresa" exact component={DashboardEmpresa} />
                    <Route path="/desenvolvedor" exact component={Desenvolvedor} />
                    <Route path="/ranking" exact component={Ranking} />
                    <Route path="/indicacao" exact component={Indicacao} />
                    <Route path="/empresa" exact component={Empresa} />
                    <Route path="/empresas" exact component={ListEmpresas} />
                    <Route path="/vaga" exact component={Vaga} />
                    <Route path="/showVagaEmpresa" exact component={ShowVagaEmpresa} />
                    <Route path="/updateVaga" exact component={UpdateVaga} />
                    <Route path="/showDesenvolvedor/:idDesenvolvedor" exact component={ShowDesenvolvedor} />
                    <Route path="/showIndicacao/:idIndicacao" exact component={ShowIndicacao} />
                    <Route path="/vagaEmpresa" exact component={VagasEmpresa} />
                    <Route path="/vagaEmpresa/:empresa" exact component={VagasEmpresa} />
                    <Route path="/findVagas" exact component={BuscaVagas} />
                    <Route path="/findProfissionais" exact component={BuscaProfissionais} />
                    <Route path="/usuarios" exact component={Usuarios} />
                    <Route path="/usuario/:email" exact component={Usuario} />
                </div>
                {
                    window.location.href.indexOf("dashboard") !== -1 &&
                    <AppFooter />
                }
                <div className="layout-mask"></div>
            </div>
        );
    }
}

export default App;
