import React, { Component } from 'react';

export class AppFooter extends Component {

    render() {
        return  (
            <div className="layout-footer">
                Thebestdevs os melhores desenvolvedores, as melhores empresas em um só lugar. 
                <a href='mailto:contato@thebestdevs.com.br'> Fale Conosco</a> 
            </div>
        );
    }
}