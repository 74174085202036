import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Panel } from 'primereact/panel';
import React, { Component } from 'react';
import api from '../../service/api';
import { isAuthenticated } from '../../service/auth';
import ReactGA from 'react-ga';
import { Event } from '../../service/analytics';

export class ShowIndicacao extends Component {
    constructor() {
        super();
        this.state = {
            indicacao: {}
        }
    }
    componentDidMount() {
        if (!isAuthenticated()) {
            window.location.href = `/#/login`;
        }
        this.getIndicacaoById(this.props.match.params.idIndicacao);
        this.initializeReactGA();
    }

    initializeReactGA() {
        ReactGA.initialize('UA-167877479-1');
        ReactGA.pageview('/showIndicacao');
    }

    getIndicacaoById = async (id) => {
        const response = await api.get('/indicacoes/' + id);
        this.setState({ indicacao: response.data });
    }

    voltar() {
        window.history.go(-1);
    }
    
    indicar() {
        Event("Desenvolvedores","Indicação","Botao indicar acionado");
        window.location.href = `/#/indicacao`;
    }
    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <h1>Exibir Indicação</h1>
                        <div className="p-grid p-fluid">
                            <Growl ref={(el) => this.growl = el} />
                            <div className="p-col-12 p-lg-12">
                                <div className="p-col-12 p-md-12">
                                    <Panel header="Dados Pessoais">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="cb2"><b>Nome:</b> {this.state.indicacao.nome}</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="cb2"><b>Email:</b><a href={`mailto:${this.state.indicacao.email}`}> {this.state.indicacao.email} </a> </label>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <label htmlFor="cb2"><b>Sexo:</b> {this.state.indicacao.sexo === 'M' ? 'Masculino' : 'Feminino'}</label>
                                        </div>
                                    </Panel>
                                </div>
                            </div>
                            <div className="p-col-6 p-md-3" style={{ align: 'right' }}>
                                <Button label="Indicar" icon="pi pi-share-alt" iconPos="left" className="p-button-raised p-button-rounded p-button-primary" style={{ marginBottom: '10px' }} onClick={this.indicar} />
                            </div>
                            <div className="p-col-6 p-md-3" style={{ align: 'right' }}>
                                <Button label="Voltar" icon="pi pi-arrow-left" iconPos="left" className="p-button-raised p-button-rounded p-button-secondary" style={{ marginBottom: '10px' }} onClick={this.voltar} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}